import FieldController from "../FieldController"

import "./styles.css"


const PasswordField = (props) => {
  return (
    <FieldController
      {...props}
      render={({ field, className, placeholder, error }) => {
        return (
          <div className={`${className} textField`}>
            {props.label && <label>{props.label} {props.required && <abbr title="обязательное поле">*</abbr>}</label>}
            <input type="password" placeholder={placeholder} {...field} disabled={props.disabled} />
            {error && <div className="field__error">{error.message}</div>}
          </div>
        )
      }}
    />
  );
};

export default PasswordField;
