import { useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/building/api'
import TopBarSelect from 'components/TopBarSelect'


const BuildingSelect = ({ currentSlug, onLoaded }) => {
  const buildingList = useSelector(state => state.building.list);
  const [getObjectList, getObjectListProps] = useLazyGetListQuery();

  useEffect(() => {
    getObjectList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getObjectListProps.isSuccess && onLoaded) {
      onLoaded(buildingList);
    }
    // eslint-disable-next-line
  }, [getObjectListProps.isSuccess, onLoaded]);

  const detail = useMemo(() => {
    if (buildingList && buildingList.length) {
      if (currentSlug) {
        return buildingList.find(item => item.slug === currentSlug)
      } else {
        return buildingList[0];
      }
    }
    return null;
  }, [buildingList, currentSlug]);

  return (
    <TopBarSelect
      items={buildingList}
      currentItem={detail}
      renderMain={item => detail.name}
      renderItem={item => (
        <NavLink
          key={`item${item.id}`}
          className="item"
          to={`/scheme/${item.slug}/`}
          replace={true}
        >
          {item.name}
        </NavLink>
      )}
      isLoading={getObjectListProps.isLoading}
    />
  );
}

export default BuildingSelect
