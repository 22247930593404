import { api } from 'redux/api/api'


const EventApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventList: builder.query({
      query: (params = null) => ({
        url: 'event/event/',
        params: params,
      }),
    }),
    getEventSimpleList: builder.query({
      query: (params = null) => ({
        url: 'event/event/',
        params: params,
      }),
    }),
    getEventDetail: builder.query({
      query: (id) => ({
        url: `event/event/${id}/`,
      }),
    }),
  }),
});

export default EventApi;

const {
  useLazyGetEventListQuery,
  useLazyGetEventSimpleListQuery,
  useLazyGetEventDetailQuery,
} = EventApi;

export {
  useLazyGetEventListQuery as useLazyGetListQuery,
  useLazyGetEventSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetEventDetailQuery as useLazyGetDetailQuery,
};

