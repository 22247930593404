import { useState } from 'react'
import Barcode from 'react-barcode'

import './styles.css'


const Card = ({ size, name, code, number }) => {
  const [showBarcode, setShowBarcode] = useState(false);

  const chunks = [number.substring(0, 4), number.substring(4, 7), number.substring(7, 10)];

  return (
    <div className={`clubCard ${code || 'black'} ${size || ''} ${showBarcode && 'isFlipped'}`} onClick={() => setShowBarcode(!showBarcode)}>
      <div className="clubCard__inner">
        <div className="clubCard__face clubCard__face--front">
          <div className="clubCard__title">{name}</div>
          <div className="clubCard__number">{chunks.join(' ')}</div>
          <div className="clubCard__btn">Показать карту</div>
        </div>

        <div className="clubCard__face clubCard__face--back">
          <div className="clubCard__barcode">
            <Barcode
              value={number}
              displayValue={true}
              margin={12}
              marginLeft={16}
              marginRight={16}
              font="PF DinDisplay Pro"
              fontSize={16}
              width={3}
              height={80}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
