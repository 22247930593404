import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/tenant/api'
import TenantCard from 'models/tenant/components/TenantCard'
import TenantItem from 'models/tenant/components/TenantItem'

import "./styles.css"


const TenantList = ({ mode, searchText, categorySlug, serviceSlug }) => {
  const tenants = useSelector(state => state.tenant.list);

  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    let params = {};
    if (searchText) {
      params['search'] = searchText;
    }
    if (categorySlug) {
      params['category'] = categorySlug;
    }
    if (serviceSlug) {
      params['services'] = serviceSlug;
    }

    getObjectList(params);
    // eslint-disable-next-line
  }, [searchText, categorySlug, serviceSlug]);

  return (
    <div className={mode === 1 ? "tenantGrid": "tenantList"}>
      {tenants.map(tenant => {
        if (mode === 1) {
          return <TenantCard key={`tenant${tenant.slug}`} tenant={tenant} />
        }
        return <TenantItem key={`tenant${tenant.slug}`} tenant={tenant} />
      })}
    </div>
  );
};

export default TenantList;
