import { useEffect, useMemo } from "react"
import { useDispatch } from 'react-redux'

import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";

import { appActions } from 'redux/features/appSlice'
import SvgIcon from "components/SvgIcon"

import "./styles.css"

const myCustomLocale = {
  // months list by order
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],

  // week days by order
  weekDays: [
    {
      name: 'Понедельник',
      short: 'Пн',
    },
    {
      name: 'Вторник',
      short: 'Вт',
    },
    {
      name: 'Среда',
      short: 'Ср',
    },
    {
      name: 'Четверг',
      short: 'Чт',
    },
    {
      name: 'Пятница',
      short: 'Пт',
    },
    {
      name: 'Суббота',
      short: 'Сб',
      isWeekend: true,
    },
    {
      name: 'Воскресенье', // used for accessibility 
      short: 'Вс', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Следующий месяц',
  previousMonth: 'Предыдущий месяц',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Выбрать...',

  // for input range value
  from: 'от',
  to: 'до',


  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
}


function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

const DatePicker = ({ show, value, onChange, onClose }) => {
  let cls = "dateTimePicker";
  if (show) cls += " show";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.toggleFixed(show));
    dispatch(appActions.toggleModal(show));
  }, [dispatch, show]);

  const defaultValue = useMemo(() => {
    if (value) {
      const parts = value.split('-');
      return {
        year: parseInt(parts[0]),
        month: parseInt(parts[1]),
        day: parseInt(parts[2]),
      };
    }
    return null;
  }, [value]);

  return (
    <div className={cls}>
      <div className="dateTimePicker__title">Выберите дату</div>

      <div className="popupClose" onClick={onClose}>
        <SvgIcon icon="close" />
      </div>

      <Calendar
        value={defaultValue}
        onChange={(newValue) => {
          onChange(`${newValue.year}-${pad(newValue.month, 2)}-${pad(newValue.day, 2)}`)
        }}
        shouldHighlightWeekends
        locale={myCustomLocale}
      />

      <div>

      </div>
    </div>
  );
};

export default DatePicker;
