import { useEffect, useState } from 'react'

import './styles.css'


const Tabs = ({ style, fit, tabs, defaultActiveTab, onTabChange }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (tabs.length > 0) {
      let initialTabIndex = defaultActiveTab || 0;
      if (tabs.length <= initialTabIndex) {
        initialTabIndex = 0;
      }
      setActiveTabIndex(initialTabIndex);
      onTabChange && onTabChange(tabs[initialTabIndex], initialTabIndex)
    }
    // eslint-disable-next-line
  }, [setActiveTabIndex, tabs]);

  const changeTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
    onTabChange && onTabChange(tabs[tabIndex], tabIndex)
  }

  return (
    <div className={`tabs ${fit ? 'tabs_fit': ''}`} style={style}>
      {tabs.map((tab, index) => (
        <div
          key={`tab${tab?.id || index}`}
          className={`tabsTab ${index === activeTabIndex ? 'active' : ''}`}
          onClick={() => changeTab(index)}>
          {tab.name}
        </div>
      ))}
    </div>
  );
}

export default Tabs
