import { api } from 'redux/api/api'


const HallAccessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHallAccessList: builder.query({
      query: (params = null) => ({
        url: 'hall/access/',
        params: params,
      }),
    }),
    getHallAccessDetail: builder.query({
      query: (id) => ({
        url: `hall/access/${id}/`,
      }),
    }),
    getHallAccessForEdit: builder.query({
      query: (id) => ({
        url: `hall/access/${id}/?forEdit=1`,
      }),
    }),

    updateHallAccess: builder.mutation({
      query(values) {
        const url = values.id ? `hall/access/${values.id}/`: 'hall/access/';
        const method = values.id ? `PUT`: 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
  }),
});

export default HallAccessApi;

const {
  useLazyGetHallAccessListQuery,
  useLazyGetHallAccessDetailQuery,
  useLazyGetHallAccessForEditQuery,
  useUpdateHallAccessMutation,
} = HallAccessApi;

export {
  useLazyGetHallAccessListQuery as useLazyGetListQuery,
  useLazyGetHallAccessDetailQuery as useLazyGetDetailQuery,
  useLazyGetHallAccessForEditQuery as useLazyGetForEditQuery,
  useUpdateHallAccessMutation as useUpdateMutation,
};


