import { NavLink } from "react-router-dom"

import { dateFormat, dateTimeFormat, timeFormat } from "utils/helpers"
import HallRequestStatus from '../HallRequestStatus'

import "./styles.css"


const HallRequestListItem = ({ request }) => {
  return (
    <NavLink className="requestItem" to={`/profile/hallrequest/${request.id}/`}>
      <div className="requestItem__top">
        <div className="requestItem__number">Заявка №{request.id}</div>
        <div className="requestItem__date">{dateTimeFormat(request.dateCreated)}</div>
      </div>

      <div className="requestItem__question">{request.hall.name}</div>
      <div className="requestItem__last">
        <span>{dateFormat(request.eventStart)} </span>
        <span>{timeFormat(request.eventStart)}-{timeFormat(request.eventEnd)}</span>
      </div>

      <HallRequestStatus status={request?.status} layout="vertical" />
    </NavLink>
  );
};

export default HallRequestListItem;
