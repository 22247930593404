import { useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

import { useLazyGetDetailQuery } from 'models/hallRequest/api'
import { Page, TopBar } from 'layout'
import HallRequestDetail from 'models/hallRequest/components/HallRequestDetail'

import './styles.css'


const HallRequestDetailPage = () => {
  let { requestId } = useParams();
  const requestDetail = useSelector(state => state.hallRequest.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail(requestId);
    // eslint-disable-next-line
  }, [requestId]);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={`Заявка №${requestId}`} showBack={true} />}
      hideNav={true}
      isLoading={getObjectDetailProps.isLoading}
    >
      <HallRequestDetail requestDetail={requestDetail} />
    </Page>
  );
};

export default HallRequestDetailPage;
