import "./styles.css"


const FixedTopPanel = ({ children }) => {
  let cls = "fixedTopPanel";

  return (
    <div className={cls}>
      {children}
    </div>
  );
};

export default FixedTopPanel;
