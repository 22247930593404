import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

import Drawer from 'components/Drawer'
import Navigation from 'components/Navigation'


const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const showDrawer = useSelector(state => state.app.showDrawer);
  const showNavigation = useSelector(state => state.app.showNavigation);
  const isModal = useSelector(state => state.app.isModal);
  const isFixed = useSelector(state => state.app.isFixed);
  const showSearchPage = useSelector(state => state.search.showSearchPage);

  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    //const noPhone = !user?.phone || user?.phone.startsWith('@');
    if (user && (!user?.first_name || !user?.last_name)) {
      navigate('/profile/edit/', { state: { from: location.state?.from || '/', replace: true } });
    }
    // eslint-disable-next-line
  }, [token, user?.first_name, user?.last_name, location.state?.from, navigate]);

  useEffect(() => {
    if (isFixed) {
      document.body.classList.add('fixed')
    } else {
      document.body.classList.remove('fixed')
    }
  }, [isFixed]);

  useEffect(() => {
    if (showSearchPage) {
      document.body.classList.add('showSearchPage')
    } else {
      document.body.classList.remove('showSearchPage')
    }
  }, [showSearchPage]);

  let cls = 'app';
  if (window.navigator.standalone) cls += ' app_isStandalone';
  if (isModal) cls += ' app_isModal';
  if (showDrawer) cls += ' app_showDrawer';
  return (
    <div className={cls}>
      <Outlet />

      {showNavigation && <Navigation />}
      <Drawer />
    </div>
  );
};

export default Layout;
