import { useMemo, useRef } from 'react'

import PlaceInfo from './PlaceInfo'
import PlaceEntrance from './PlaceEntrance'

import './styles.css'


const SchemePlace = ({ place, isCurrent, selectedPlace, onOpenPlace }) => {
  const ref = useRef();

  const [width, height] = useMemo(() => {
    const width = Math.max.apply(Math, place.schemePoints.map(o => o[0]));
    const height = Math.max.apply(Math, place.schemePoints.map(o => o[1]));
    return [width, height]
  }, [place.schemePoints]);

  const isSelected = selectedPlace?.id === place.id;

  let isBusy = false;
  let cls = "schemePlace";
  if (isCurrent) cls += " isCurrent";
  if (isSelected) cls += " isSelected";
  if (place.isUsed && (place.hall || place.tenant)) {
    cls += " isUsed";
  } else {
    cls += ` ${place.status}`;
    if (place.isUsed || place.status === 'booked' || place.status === "busy") {
      isBusy = true;
    }
  }

  const style = {
    width: `${width}px`,
    height: `${height}px`,
    transform: `translate(${place.schemePosition[0]}px, ${place.schemePosition[1]}px)`,
    clipPath: `url(#myPath${place.id})`,
  };

  const isClosed = place.status === 'closed' || place.status === 'booked';
  const tenantOrHall = place.tenant || place.hall;
  const number = (!isClosed || tenantOrHall) ? place.number: '';

  return (
    <>
      <div
        ref={ref}
        className={cls}
        style={style}
        onClick={() => onOpenPlace && onOpenPlace(place, place.hall, place.tenant)}
      >
        <div className="schemePlace__inner">
          {number && <div className="schemePlace__number">{number}</div>}
          <PlaceInfo place={place} hall={place.hall} tenant={place.tenant} width={width} height={height} />
        </div>

        <svg className="schemePlace__svg" width={width} height={height}>
          <defs>
          <pattern id="pattern" patternUnits="userSpaceOnUse" width="18.5" height="18.5" patternTransform="rotate(45)">
              <line x1="0" y="0" x2="0" y2="18.5" stroke="#C94141" strokeWidth="30" />
            </pattern>
            <pattern id="pattern2" patternUnits="userSpaceOnUse" width="18.5" height="18.5" patternTransform="rotate(45)">
              <line x1="0" y="0" x2="0" y2="18.5" stroke="#999" strokeWidth="30" />
            </pattern>
          </defs>
          <polygon
            points={place.schemePoints}
            fill={isBusy ? `url(${isSelected ? '#pattern': '#pattern2' })` : null}
            opacity={isBusy ? "0.5" : "1.0"}
          />
        </svg>
      </div>
      <svg style={{ position: 'absolute' }}>
        <clipPath id={`myPath${place.id}`}>
          <polygon points={place.schemePoints} />
        </clipPath>
      </svg>
      <PlaceEntrance
        place={place}
        isSelected={isSelected || isCurrent}
        outerPosition={place.schemePosition}
      />
    </>
  );
}

export default SchemePlace
