import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/news/api'
import NewsListItem from 'models/news/components/NewsListItem'

import "./styles.css"


const NewsList = () => {
  const posts = useSelector(state => state.news.list);
  
  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    getObjectList();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="newsList">
      {posts.map(post => <NewsListItem key={`news${post.slug}`} post={post}/>)}
    </div>
  );
};

export default NewsList;
