import { useSelector } from 'react-redux'

import FeedbackRequestListItem from 'models/feedback/components/FeedbackRequestListItem'

import "./styles.css"


const FeedbackRequestList = () => {
  const requests = useSelector(state => state.feedback.requests);

  return (
    <div className="requestList">
      {requests.map(request => <FeedbackRequestListItem key={`request${request.id}`} request={request}/>)}
    </div>
  );
};

export default FeedbackRequestList;
