import { NavLink } from "react-router-dom"

import "./styles.css"


const TenantItem = ({ tenant }) => {
  const logo = tenant?.icon && tenant?.icon[0];
  return (
    <NavLink className="tenantItem" to={`/tenant/${tenant.slug}/`}>
      {logo && <img className="tenantItem__logo" src={logo?.url} alt={tenant.name} />}
      <div className="tenantItem__body">
        <div className="tenantItem__title">{tenant.name}</div>
      </div>
    </NavLink>
  );
};

export default TenantItem;
