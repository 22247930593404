import "./styles.css"


const FixedBottomPanel = ({ children, withNav }) => {
  let cls = "fixedBottomPanel";
  if (withNav) cls += " withNav";

  return (
    <div className={cls}>
      {children}
    </div>
  );
};

export default FixedBottomPanel;
