import { getFullName, phoneFormat, dateFormat, timeFormat } from "utils/helpers"
import { ReadonlyFieldset, ReadonlyField } from "components/forms"
import HallAccessStatus from '../HallAccessStatus'

import "./styles.css"


const HallAccessDetail = ({ accessDetail }) => {
  if (!accessDetail) return null;

  const client = accessDetail?.client;
  const hall = accessDetail?.hall;
  const eventStart = accessDetail?.eventStart;
  //const eventEnd = accessDetail?.eventEnd;

  return (
    <>
      <ReadonlyFieldset>
        <ReadonlyField value={getFullName(client)} />
        <ReadonlyField value={phoneFormat(client?.phone) || client?.email} />

        <ReadonlyField color="light" label="Статус" value={<HallAccessStatus status={accessDetail?.status} />} />

        <ReadonlyField color="light" label="Зал" value={hall?.name} />

        <ReadonlyField color="light" label="Название мероприятия" value={accessDetail?.eventName} />

        <ReadonlyField color="light" label="Дата проведения" value={dateFormat(eventStart)} />

        <ReadonlyField color="light" label="Время проведения" value={timeFormat(eventStart)} />

        <ReadonlyField color="light" label="Длительность проведения" value={`${accessDetail?.eventDuration} ч.`} />

        <ReadonlyField color="light" label="Комментарий" value={accessDetail?.eventComment} />
      </ReadonlyFieldset>
    </>
  );
};

export default HallAccessDetail;
