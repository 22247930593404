import { NavLink } from "react-router-dom"

import "./styles.css"


const FeedbackPanel = () => {
  return (
    <div className="feedbackPanel">
      <NavLink className="feedbackPanel__inner" to="/feedback/">
        <div className="feedbackPanel__title">Связь с администрацией</div>
        <div className="feedbackPanel__text">Задайте нам ваши вопросы</div>
      </NavLink>
    </div>
  );
};

export default FeedbackPanel;
