import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import { appInit } from 'redux/features/appSlice'
import Layout from 'pages'

import Page404 from 'pages/Page404'

import AuthOTPLoginPage from 'pages/AuthOTPLoginPage'
import AuthPassLoginPage from 'pages/AuthPassLoginPage'
import AuthOauthPage from 'pages/AuthOauthPage'

import HomePage from 'pages/HomePage'
import AddToHomePage from 'pages/AddToHomePage'

import EventPage from 'pages/EventPage'
import EventDetailPage from 'pages/EventDetailPage'

import HallPage from 'pages/HallPage'
import HallDetailPage from 'pages/HallDetailPage'
import HallOrderPage from 'pages/HallOrderPage'
//import HallPassPage from 'pages/HallPassPage'
import HallAccessPassPage from 'pages/HallAccessPassPage'

import TenantPage from 'pages/TenantPage'
import TenantDetailPage from 'pages/TenantDetailPage'

import SchemePage from 'pages/SchemePage'

import NewsPage from 'pages/NewsPage'
import NewsDetailPage from 'pages/NewsDetailPage'

import ParkingPage from 'pages/ParkingPage'
import ParkingDetailPage from 'pages/ParkingDetailPage'
//import ParkingPassPage from 'pages/ParkingPassPage'
import ParkingFreePassPage from 'pages/ParkingFreePassPage'

import FeedbackPage from 'pages/FeedbackPage'
import FeedbackRequestPage from 'pages/FeedbackRequestPage'
import FeedbackRequestListPage from 'pages/FeedbackRequestListPage'
import FeedbackRequestDetailPage from 'pages/FeedbackRequestDetailPage'

import ClubCardPage from 'pages/ClubCardPage'
import ClubCardRequestPage from 'pages/ClubCardRequestPage'

import ProfilePage from 'pages/ProfilePage'
import ProfileEditPage from 'pages/ProfileEditPage'

import HallRequestListPage from 'pages/HallRequestListPage'
import HallRequestDetailPage from 'pages/HallRequestDetailPage'


const ProtectedRoute = () => {
  const token = useSelector(state => state.auth.token);
  const location = useLocation();

  const path = location.pathname + (location.search ? location.search : '');


  return token ? (
    <Outlet />
  ) : (
    <Navigate to='/login/' state={{ from: path }} replace={true} />
  );
};


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInit());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />

        <Route path="add-to-home/" element={<AddToHomePage />} />

        <Route path="/news/">
          <Route path=":postSlug/" element={<NewsDetailPage />} />
          <Route index element={<NewsPage />} />
        </Route>

        <Route path="/events/">
          <Route path=":eventId/" element={<EventDetailPage />} />
          <Route path="date/:date/" element={<EventPage />} />
          <Route index element={<EventPage />} />
        </Route>

        <Route path="/hall/">
          <Route path=":hallSlug/" element={<HallDetailPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="order/" element={<HallOrderPage />} />
            <Route path="access/:accessId/" element={<HallAccessPassPage />} />
          </Route>
          <Route index element={<HallPage />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/parking/">
            <Route path=":tariffId/" element={<ParkingDetailPage />} />
            <Route path="free/" element={<ParkingFreePassPage />} />
            <Route index element={<ParkingPage />} />
          </Route>
        </Route>

        <Route path="/tenant/">
          <Route path=":tenantSlug/" element={<TenantDetailPage />} />
          <Route index element={<TenantPage />} />
        </Route>

        <Route path="/scheme/">
          <Route path=":buildingSlug/" element={<SchemePage />} />
          <Route index element={<SchemePage />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/profile/">
            <Route index element={<ProfilePage />} />
            <Route path="edit/" element={<ProfileEditPage />} />
            <Route path="hallrequest/" element={<HallRequestListPage />} />
            <Route path="hallrequest/:requestId/" element={<HallRequestDetailPage />} />
          </Route>
        </Route>

        <Route path="/feedback/">
          <Route path="request/" element={<FeedbackRequestPage />} />
          <Route path="history/" element={<FeedbackRequestListPage />} />
          <Route path="history/:requestId/" element={<FeedbackRequestDetailPage />} />
          <Route index element={<FeedbackPage />} />
        </Route>

        <Route path="/club/">
          <Route index element={<ClubCardPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="request/" element={<ClubCardRequestPage />} />
            <Route path="request/existing/" element={<ClubCardRequestPage existing={true} />} />
          </Route>
        </Route>
      </Route>

      <Route path='/login/' element={<AuthPassLoginPage />} />
      <Route path='/login/yandex/' element={<AuthOauthPage />} />
      <Route path='/login/mail/' element={<AuthOauthPage />} />
      <Route path='/login/vk/' element={<AuthOauthPage />} />
      <Route path='/login/otp/' element={<AuthOTPLoginPage />} />

      <Route path='*' element={<Page404 />} />
    </Routes>
  );
}

export default App;
