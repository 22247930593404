import React from 'react'

import { Page, TopBar, Panel } from 'layout'
import HallRequestList from 'models/hallRequest/components/HallRequestList'

import './styles.css'


const FeedbackRequestListPage = () => {
  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="История бронирования залов" showBack={true} />}
      hideNav={true}
    >
      <Panel padding="0">
        <HallRequestList/>
      </Panel>
    </Page>
  );
};

export default FeedbackRequestListPage;
