import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import Popup from 'components/Popup'
import PropertyList from 'components/PropertyList'

import './styles.css'


const PlaceDetail = ({ place }) => {
  const area = parseFloat(place?.area);

  const isFree = useMemo(() => {
    const status = place?.status;
    return !place?.isUsed && (status === 'free' || status === 'freeToBuy' || status === 'freeToRent');
  }, [place]);

  return (
    <div className="placeDetail">
      <div className="placeDetail__main">
        <PropertyList
          style={{ padding: '0', border: '0' }}
          items={[
            { label: 'Назначение:', value: `${place?.purpose}` },
            { label: 'Отделка:', value: `${place?.furnish}` },
            { label: 'Площадь:', value: area ? `${area} м²` : '' },
          ]}
        />

        {place?.text && <div dangerouslySetInnerHTML={{ __html: place?.text }} />}
      </div>

      {isFree && <div className="placeDetail__bottom">
        <div className="btn">Забронировать помещение</div>
      </div>}
    </div>
  );
}


const TenantDetail = ({ tenant, place }) => {
  const image = tenant?.images[0];

  console.log('tenant', tenant);
  console.log('place', place);

  return (
    <div className="placeDetail">
      {place?.number && <div className="placeDetail__number">каб. {place?.number}</div>}
      <div className="placeDetail__main">
        {image && <div className="placePic"><img src={image.url} alt="" /></div>}

        <PropertyList
          mode="compact"
          items={[
            { label: '', value: `${tenant?.address}` },
            { label: 'Телефон:', value: `${tenant?.phone}` },
          ]}
        />
      </div>

      <div className="placeDetail__bottom">
        <Link to={`/tenant/${tenant.slug}/`} className="btn">Подробнее</Link>
        <Link to={`/tenant/${tenant.slug}/#path`} className="btn btnBorder">Как пройти</Link>
      </div>
    </div>
  );
}


const HallDetail = ({ hall }) => {
  const image = hall?.images[0];
  const hasProps = !!(hall?.capacity || hall?.equipment);
  return (
    <div className="placeDetail">
      <div className="placeDetail__main">
        {image && <div className="placePic"><img src={image.url} alt="" /></div>}

        {hall?.teaser && <div className="placeDetail__text">{hall?.teaser}</div>}
        {hasProps &&
          <PropertyList
            mode="compact"
            items={[
              { label: 'Вместимость:', value: `${hall?.capacity}`, icon: 'people' },
              { label: 'Оборудование:', value: `${hall?.equipment}`, icon: 'presentaion' },
            ]}
          />}
      </div>

      {hall.isActive && <div className="placeDetail__bottom">
        <Link to={`/hall/${hall.slug}/`} className="btn">К описанию</Link>
      </div>}
    </div>
  );
}


const BuildingPlacePopup = ({ show, place, hall, tenant, onClose }) => {
  const title = useMemo(() => {
    if (tenant?.name || hall?.name) {
      return tenant?.name || hall?.name;
    }

    let statusCls = '';
    let statusText = '';
    let status = place?.status;

    if (place?.isUsed || status === 'booked' || status === "busy") {
      statusCls = 'isBusy';
      statusText = 'занято';
    } else if (status === 'free' || status === 'freeToBuy' || status === 'freeToRent') {
      statusCls = 'isFree';
      statusText = 'доступно';
    }

    const number = place?.number ? `Кабинет ${place?.number}`: place?.name;

    return (
      <div className="placeTitle">
        <span>{number}</span>
        {statusText && <div className={`placeStatus ${statusCls}`}>{statusText}</div>}
      </div>
    )

  }, [place, tenant, hall]);

  return (
    <Popup
      show={show}
      onClose={onClose}
      title={title}
    >
      {tenant ?
        <TenantDetail tenant={tenant} place={place} />
        : hall ?
          <HallDetail hall={hall} place={place} />
          :
          <PlaceDetail place={place} />}
    </Popup>
  );
}

export default BuildingPlacePopup
