import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Countdown from 'react-countdown'

import { Page, TopBar } from 'layout'
import { getFullNameOrEmail } from 'utils/helpers'
import QRCodePass from 'components/QRCodePass'
import { useParkingWS } from 'models/parkingPass/useHooks'

import './styles.css'


const ParkingFreePassPage = () => {
  const user = useSelector(state => state.auth.user);
  const navigate = useNavigate();

  const { freePass } = useParkingWS();

  useEffect(() => {
    if (freePass?.status === 'completed' || freePass?.status === 'expired') {
      navigate('/parking/', { replace: true });
    }
    // eslint-disable-next-line
  }, [freePass?.status]);

  return (
    <Page
      className="dark"
      renderHeader={(pageRef) => <TopBar title="Ключ доступа на паркинг" showBack={true} />}
      hideNav={true}
      isLoading={freePass === null}
    >
      {freePass?.rpsQrCode &&
        <QRCodePass
          code={freePass?.rpsQrCode}
          title="ПАРКИНГ-ключ"
          name={getFullNameOrEmail(user)}
          area="Главный паркинг"
          pass={freePass}
          expired={
            <>
              <Countdown date={freePass?.expirationDate} daysInHours={true} />
            </>
          }
        />}
    </Page>
  );
};

export default ParkingFreePassPage;
