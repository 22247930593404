import { useEffect, useMemo } from 'react'
import { NavLink } from "react-router-dom"
import { useSelector } from 'react-redux'
import moment from 'moment'

import { useLazyGetListQuery } from 'models/event/api'
import { useLazyGetListQuery as useHallLazyGetListQuery } from 'models/hall/api'

import "./styles.css"


const timeSlots = [
  ['10:00', '12:00'],
  ['12:00', '14:00'],
  ['14:00', '16:00'],
  ['16:00', '18:00'],
];


const EventList = ({ showFree, date, hall }) => {
  const eventList = useSelector(state => state.event.list);
  const hallList = useSelector(state => state.hall.list);

  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    if (date) {
      let params = {
        date: `${date.format('DD.MM.YYYY')}--${date.format('DD.MM.YYYY')}`
      }
      if (hall) {
        params.hall = hall
      }
      getObjectList(params);
    }
    // eslint-disable-next-line
  }, [date, hall])

  const [getHallList] = useHallLazyGetListQuery();
  useEffect(() => {
    getHallList();
    // eslint-disable-next-line
  }, [])

  const events = useMemo(() => {
    return eventList.map(event => {
      const start = moment(event.start);
      return { ...event, start: start };
    })
  }, [eventList]);

  const freeSlots = useMemo(() => {
    if (!date) return [];
    return timeSlots.reduce((result, timeSlot) => {
      const startTime = moment(`${date.format('YYYY-MM-DD')} ${timeSlot[0]}`, 'YYYY-MM-DD HH:mm');
      const endTime = moment(`${date.format('YYYY-MM-DD')} ${timeSlot[1]}`, 'YYYY-MM-DD HH:mm');
      const eventsByHall = events.reduce((res, event) => {
        if (event.start.isBetween(startTime, endTime, 'hours', [])) {
          let items = res[event.hall.id] || [];
          items.push(event);
          res[event.hall.id] = items;
        }
        return res;
      }, {});

      const halls = hallList.map(hall => {
        const hallEvents = eventsByHall[hall.id] || [];
        return {
          ...hall,
          events: hallEvents,
        }
      });

      const slot = {
        timeSlot, startTime, endTime, halls,
      }
      result.push(slot);
      return result;
    }, [])
    // eslint-disable-next-line
  }, [date, timeSlots, events, hallList]);

  //if (!events) return null;

  return (
    <div className="eventList">
      <div className="eventList__list">
        {showFree ?
          freeSlots.map(slot => (
            slot.halls.map(hall => {
              if (hall.events.length > 0) return null;
              return (
                <NavLink
                  key={`slot${slot.timeSlot[0]}${hall.id}`}
                  className="eventListItem free"
                  to={`/hall/order/?hall=${hall.id}&eventDate=${date.format('YYYY-MM-DD')}&eventTime=${slot.timeSlot[0]}`}
                >
                  <div className="eventListItem__time">{slot.startTime.format('HH:mm')}</div>
                  <div className="eventListItem__body">
                    <div className="eventListItem__name">Время свободно для вашего события</div>
                    <div className="eventListItem__hall">{hall.name}</div>
                  </div>
                  <div className="eventListItem__icon">
                    <svg className="icon" >
                      <use xlinkHref="/img/icons.svg#angleRight"></use>
                    </svg>
                  </div>
                </NavLink>
              )
            })
          ))
          :
          events.map(event => {
            return (
              <NavLink key={`event${event.id}`} className="eventListItem" to={`/events/${event.id}/`}>
                <div className="eventListItem__time">{event.start.format('HH:mm')}</div>
                <div className="eventListItem__body">
                  <div className="eventListItem__name">{event.name}</div>
                  <div className="eventListItem__hall">{event.hall?.name}</div>
                </div>
                <div className="eventListItem__icon">
                  <svg className="icon" >
                    <use xlinkHref="/img/icons.svg#angleRight"></use>
                  </svg>
                </div>
              </NavLink>
            )
          })
        }
      </div>
    </div>
  );
};

export default EventList;
