import SvgIcon from "components/SvgIcon"

import "./styles.css"


const Popup = ({ show, onClose, title, children }) => {
  return (
    <div className={`popup ${show ? 'active': ''}`}>
      <div className="popupClose" onClick={onClose}>
        <SvgIcon icon="close" />
      </div>

      <div className="popupTitle">{title}</div>

      {children}
    </div>
  );
}

export default Popup;
