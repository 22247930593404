import React from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

import { Page, TopBar, FixedBottomPanel } from 'layout'
import Button from 'components/Button'

import './styles.css'


const ParkingDetailPage = () => {
  let { tariffId } = useParams();
  const tariffDetail = useSelector(state => state.parking[`tariffDetail${tariffId}`]);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={`Тариф «${tariffDetail.name}»`} showBack={true} />}
      hideNav={true}
    >
      <div className="tariffDetailHeader">
        <div>
          <div className="tariffDetailHeader__title">{tariffDetail.text}</div>
          <div className="tariffDetailHeader__user"><b>Владелец:</b> Кантимеров Владлен</div>
        </div>
      </div>

      <div className="tariffDetailInfo">
        <div className="tariffDetailInfoItem">
          <div className="tariffDetailInfoItem__lbl">Срок действия:</div>
          <div className="tariffDetailInfoItem__val">бессрочно</div>
        </div>
        <div className="tariffDetailInfoItem">
          <div className="tariffDetailInfoItem__lbl">Доступно заездов/выездов:</div>
          <div className="tariffDetailInfoItem__val">{tariffDetail.count}</div>
        </div>
        <div className="tariffDetailInfoItem">
          <div className="tariffDetailInfoItem__lbl">Активность:</div>
          <div className="tariffDetailInfoItem__val">После оплаты</div>
        </div>
        <div className="tariffDetailInfoItem">
          <div className="tariffDetailInfoItem__lbl">Стоимость:</div>
          <div className="tariffDetailInfoItem__val">{tariffDetail.price} рублей</div>
        </div>
      </div>

      <FixedBottomPanel>
        <Button title={`Купить за ${tariffDetail.price} руб`} size="lg" />
      </FixedBottomPanel>
    </Page>
  );
};

export default ParkingDetailPage;
