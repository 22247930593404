import React, { useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'
import moment from 'moment'

import { useLazyGetDetailQuery } from 'models/event/api'
import { Page, TopBar } from 'layout'
import DetailHeader from 'components/DetailHeader'
import DetailText from 'components/DetailText'
import PropertyList from 'components/PropertyList'

import './styles.css'


const EventDetailPage = () => {
  let { eventId } = useParams();
  const eventDetail = useSelector(state => state.event.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail(eventId);
    // eslint-disable-next-line
  }, [eventId]);

  const start = moment(eventDetail?.start);
  const duration = moment.duration(moment(eventDetail?.end).diff(start)).asMinutes();

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={eventDetail?.name} showBack={true} />}
      hideNav={true}
      isLoading={getObjectDetailProps.isLoading}
    >
      <DetailHeader
        image={eventDetail?.images}
        logo={eventDetail?.icon}
        name={eventDetail?.name}
        title={eventDetail?.name}
        properties={[
          { icon: 'date', value: eventDetail?.date },
          { icon: 'phone', value: eventDetail?.phone },
        ]}
      />

      <PropertyList
        items={[
          { size: 'lg', value: `${eventDetail?.hall?.name}`, icon: '' },
          { value: `${duration} минут`, icon: 'clock' },
        ]}
      />

      <DetailText text={eventDetail?.text} />
    </Page>
  );
};

export default EventDetailPage;
