import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from "react-router-dom"
import Slider from "react-slick"

import { useLazyGetListQuery } from 'models/news/api'
import { Panel } from 'layout'

import "./styles.css"


const NewsSlider = () => {
  const posts = useSelector(state => state.news.list);

  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    getObjectList();
    // eslint-disable-next-line
  }, []);

  return (
    <Panel title="Новости">
      <Slider className="newsSliderSlick" dots={true} arrows={false} infinite={false} speed={500} slidesToShow={1} slidesToScroll={1}>
        {posts.map(post =>
          <NavLink key={`post${post.slug}`} className="postCard" to={`/news/${post.slug}/`}>
            <img className="postCard__img" src={post.images[0]?.url} alt={post.title} />
            <div className="postCard__body">
              <div className="postCard__title">{post.title}</div>
              </div>
          </NavLink>
        )}
      </Slider>
    </Panel>
  );
};

export default NewsSlider;
