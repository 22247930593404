import SvgIcon from "components/SvgIcon"

import "./styles.css"


const STATUSES = {
  'new': {
    title: 'Новое',
    icon: 'open',
  },
  'inProgress': {
    title: 'В обработке',
    icon: 'inProgress',
  },
  'completed': {
    title: 'Одобрено',
    icon: 'completed',
  },
  'declined': {
    title: 'Отклонено',
    icon: 'declined',
  }
};

const HallRequestStatus = ({ status, layout }) => {
  const statusItem = STATUSES[status] || STATUSES['new'];
  return (
    <div className={`requestStatus ${statusItem.icon} ${layout}`}>
      <div className="requestStatusIcon">
        <SvgIcon icon={statusItem.icon} />
      </div>
      <div className="requestStatusTitle">{statusItem.title}</div>
    </div>
  );
};

export default HallRequestStatus;
