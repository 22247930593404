const ENV = {
  local: {
    appUrl: 'http://localhost:3001',
    apiUrl: 'http://192.168.1.144:8000',
    wsUrl: 'ws://192.168.1.144:8000/ws/',
  },
  production: {
    appUrl: 'https://app.territory3000.ru',
    apiUrl: 'https://office-api.territory3000.ru',
    wsUrl: 'wss://office-ws.territory3000.ru/ws/',
  },
};

const urls = ENV['production'];

export const APP_URL = urls.appUrl;
export const API_URL = urls.apiUrl;
export const WS_URL = urls.wsUrl;

export const GOOGLE_CLIENT_ID = "426739924055-58ve563idvp27nc2amdkr510u72cke0g.apps.googleusercontent.com";
export const YANDEX_CLIENT_ID = "4f3bb0f7b94347d38b30acfc26b51f51";
export const MAIL_CLIENT_ID = "db01988be0e54b059a4666d3804a224d";
export const VK_APP_ID = "51840094";
