import { useEffect, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import moment from 'moment'

import SvgIcon from "components/SvgIcon"

import "./styles.css"


const EventWeekDates = ({ date, today }) => {
  const [weeksIndexList, currentWeek] = useMemo(() => {
    const activeDateMonday = moment(date).startOf('week');
    const todayMonday = moment(today).startOf('week');
    const diffWeeks = moment.duration(activeDateMonday.diff(todayMonday)).weeks();
    let idx, indexList = [], currentWeek = diffWeeks;
    if (diffWeeks < 0) {
      for (idx = diffWeeks; idx <= 1; idx++) {
        indexList.push(idx);
      }
    } else {
      const maxCount = diffWeeks > 1 ? diffWeeks: 1;
      for (idx = 0; idx <= maxCount; idx++) {
        indexList.push(idx);
      }
    }
    return [indexList, currentWeek];
  }, [date, today]);

  const [activeWeek, setActiveWeek] = useState(currentWeek);

  useEffect(() => {
    setActiveWeek(currentWeek);
  }, [currentWeek]);

  const weeks = useMemo(() => {
    return weeksIndexList.reduce((res, idx) => {
      const monday = moment(today).startOf('week').add(idx * 7, 'days');

      const isCurrent = idx === 0;
      const days = [
        monday,
        moment(monday).add(1, 'days'),
        moment(monday).add(2, 'days'),
        moment(monday).add(3, 'days'),
        moment(monday).add(4, 'days'),
        moment(monday).add(5, 'days'),
        moment(monday).add(6, 'days'),
      ]
      res.push({ isCurrent, days })
      return res;
    }, []);
  }, [today, weeksIndexList]);

  const todayStr = useMemo(() => {
    return today.format('DD-MM-YYYY');
  }, [today]);

  const goToWeek = (dir) => {
    let nextWeek = activeWeek + dir;
    if (nextWeek < 0) nextWeek = 0;
    if (nextWeek > weeks.length - 1) nextWeek = weeks.length - 1;
    setActiveWeek(nextWeek);
  }

  const classNameChoose = (cls) => ({ isActive }) => {
    return isActive ? "weekDatesItem active " + cls : "weekDatesItem " + cls
  };

  if (!date) return null;

  return (
    <div className="weekDates">
      <div className="weekDates__prev" onClick={() => goToWeek(-1)}>
        <SvgIcon icon="angleLeft" />
      </div>
      <div className="weekDates__main">
        <div className="weekDates__inner" style={{ transform: `translateX(-${activeWeek * 100}%)` }}>
          {weeks.map((week, idx) => (
            <div key={`week${idx}`} className={`weekDates__week ${week.isCurrent ? 'current' : ''}`}>
              {week.days.map(day => {
                const dateStr = day.format('DD-MM-YYYY');
                return (
                  <NavLink key={dateStr} className={classNameChoose(dateStr === todayStr && 'current')} to={`/events/date/${dateStr}/`}>
                    <div className="weekDatesItem__day">{day.format('DD')}</div>
                    <div className="weekDatesItem__month">{day.format('MMM')}</div>
                    <div className="weekDatesItem__weekday">{day.format('dd')}</div>
                  </NavLink>
                )
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="weekDates__next" onClick={() => goToWeek(1)}>
        <SvgIcon icon="angleRight" />
      </div>
    </div>
  );
};

export default EventWeekDates;
