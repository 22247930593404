import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { appActions } from 'redux/features/appSlice'
import Loader from 'components/Loader'
import AddToHomeScreen from "components/AddToHomeScreen"
import SearchPage from 'models/search/components/SearchPage'

import "./styles.css"


const Page = ({ className, children, renderHeader, paddingTop, withHeader, hideNav, isFixed, isLoading }) => {
  const dispatch = useDispatch();
  const ref = useRef();

  let cls = "page";
  if (withHeader) cls += " page_withHeader";
  if (!hideNav) cls += " page_withNav";
  if (className) cls += ` ${className}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(appActions.toggleNavigation(!hideNav))
  }, [hideNav, dispatch])

  useEffect(() => {
    dispatch(appActions.toggleFixed(isFixed));
  }, [dispatch, isFixed]);

  let bodyStyle = {
    paddingTop: `${paddingTop}px`,
  }

  return (
    <div ref={ref} className={cls}>
      {isLoading ?
        <div className="pageLoader"><Loader isLoading={true} /></div>
        :
        <>
          {renderHeader && renderHeader(ref)}

          <div className="pageBody" style={bodyStyle}>{children}</div>

          <AddToHomeScreen />
        </>
      }

      <SearchPage />
    </div>
  );
};

export default Page;
