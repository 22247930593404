import { NavLink } from "react-router-dom"

import "./styles.css"


const HallListItem = ({ hall }) => {
  const icon = hall.icon && hall.icon[0];
  return (
    <NavLink key={`hall${hall.slug}`} className="hallListItem" to={`/hall/${hall.slug}/`}>
      {icon?.url && <img className="hallListItem__icon" src={icon?.url} alt={hall.name} />}
      <div className="hallListItem__body">
        <div className="hallListItem__title">{hall.name}</div>
        <div className="hallListItem__capacity">{hall.capacity}</div>
      </div>
    </NavLink>
  );
};

export default HallListItem;
