import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  results: null,
  isLoading: false,
  showSearchPage: false,
};

export const searchSlice = createSlice({
  initialState,
  name: 'searchSlice',
  reducers: {
    showSearchPage: (state, { payload }) => {
      if (payload !== state.showSearchPage) {
        state.results = null;
        state.isLoading = false;
        state.showSearchPage = payload;
      }
    },
    clearResults: (state) => {
      state.results = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.search.matchPending, (state, action) => {
        state.isLoading = true;
      })
      .addMatcher(api.endpoints.search.matchFulfilled, (state, action) => {
        state.isLoading = false;
        state.results = action.payload;
      })
  },
});

export default searchSlice.reducer;

export const searchActions = searchSlice.actions;
