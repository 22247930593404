import { NavLink } from "react-router-dom"

import "./styles.css"


const HallListItem = ({ post }) => {
  return (
    <NavLink className="newsListItem" to={`/news/${post.slug}/`}>
      <img className="newsListItem__image" src={post.images[0]?.url} alt={post.title} />
      <div className="newsListItem__title">{post.title}</div>
      <div className="eventListItem__icon">
        <svg className="icon" >
          <use xlinkHref="/img/icons.svg#angleRight"></use>
        </svg>
      </div>
    </NavLink>
  );
};

export default HallListItem;
