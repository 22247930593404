import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,

  detail: null,

  freePass: null,
};

export const parkingPassSlice = createSlice({
  initialState,
  name: 'parkingPassSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getParkingPassList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
      })
      .addMatcher(api.endpoints.getParkingPassDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.getParkingPassFree.matchPending, (state, action) => {
        state.freePass = null;
      })
      .addMatcher(api.endpoints.getParkingPassFree.matchFulfilled, (state, action) => {
        if (action.payload?.rpsQrCode) {
          state.freePass = action.payload;
        } else {
          state.freePass = null;
        }
      })

      .addMatcher(api.endpoints.createParkingPassFree.matchFulfilled, (state, action) => {
        if (action.payload?.rpsQrCode) {
          state.freePass = action.payload;
        } else {
          state.freePass = null;
        }
      })
  },
});

export default parkingPassSlice.reducer;
