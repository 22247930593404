import { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { useLazySearchQuery } from 'models/search/api'
import { searchActions } from 'models/search/searchSlice'
import "./styles.css"


const SearchTop = ({ style }) => {
  const inputRef = useRef()

  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const [search] = useLazySearchQuery();

  const onFocus = () => {
    setFocused(true);
    dispatch(searchActions.showSearchPage(true))
  }

  const cancel = () => {
    inputRef.current.value = "";
    setFocused(false);
    dispatch(searchActions.showSearchPage(false))
  }

  const onKeyPressed = (e) => {
    if (e.target.value.length > 2) {
      search({ q: e.target.value });
    }
  }

  return (
    <div className={`searchTop ${focused ? 'searchTop_focused' : ''}`} onFocus={onFocus} style={style}>
      <div className="searchBox">
        <div className="searchBox__icon">
          <svg className="icon" >
            <use xlinkHref="/img/icons.svg#search"></use>
          </svg>
        </div>
        <input
          ref={inputRef}
          className="searchBox__input"
          name="q"
          type="text"
          autoComplete="off" autoCorrect="off" spellCheck="false" placeholder="Поиск"
          onChange={onKeyPressed}
        />
      </div>

      <div className="searchTopCancel" onClick={cancel}>Отмена</div>
    </div>
  );
}

export default SearchTop;
