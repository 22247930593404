import FieldController from "../FieldController"

import "./styles.css"


const ImageField = (props) => {
  return (
    <FieldController
      {...props}
      render={({ field, className, placeholder, error }) => {
        return (
          <div className={`${className} textField`}>
            {props.label && <label>{props.label} {props.required && <abbr title="обязательное поле">*</abbr>}</label>}
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              placeholder={placeholder}
              {...field}
              disabled={props.disabled}
              value={field.value?.fileName}
              onChange={(event) => {
                field.onChange(event.target.files[0]);
              }}
            />
            {error && <div className="field__error">{error.message}</div>}
            {props.help && <div className="field__help">{props.help}</div>}
          </div>
        )
      }}
    />
  );
};

export default ImageField;
