import { useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

import AuthApi from 'models/auth/api'
import SvgIcon from "components/SvgIcon"
import Loader from 'components/Loader'

import './styles.css'


const AuthOauthPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = useSelector(state => state.auth.token);
  const authNext = useSelector(state => state.auth.authNext);
  const [loginOauth] = AuthApi.useLazyLoginOauthQuery();

  console.log('authNext', authNext);

  useEffect(() => {
    let service;
    if (location.pathname === '/login/google/') {
      service = 'google';
    } else if (location.pathname === '/login/yandex/') {
      service = 'yandex';
    } else if (location.pathname === '/login/mail/') {
      service = 'mail';
    } else if (location.pathname === '/login/vk/') {
      service = 'vk';
    }

    loginOauth({ service, params: location.search });
    // eslint-disable-next-line
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (token) {
      navigate(authNext || location.state?.from || '/', { replace: true });
    }
  }, [token, authNext, location.state?.from, navigate]);

  const onBack = () => {
    navigate('/login/', { state: location.state })
  }

  const onClose = () => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      navigate(-1)
    }
  }

  return (
    <div className="loginPage">
      <div className="loginPageBack" onClick={onBack}>
        <SvgIcon icon="angleLeft" />
      </div>

      <div className="loginPageClose" onClick={onClose}>
        <SvgIcon icon="close" />
      </div>

      <div className="loginPageLoader">
        <Loader isLoading={true} />
      </div>
    </div>
  );
};

export default AuthOauthPage;
