import { api } from 'redux/api/api'


const SearchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: (params) => ({
        url: 'search/',
        params: params,
      }),
    }),
  }),
});

export default SearchApi;

const {
  useLazySearchQuery,
} = SearchApi;

export {
  useLazySearchQuery,
};
