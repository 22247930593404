import FieldController from "../FieldController"

import "./styles.css"


const Checkbox = ({ className, label, required, field, disabled, error, value }) => {
  return (
    <div className={`${className} checkboxField`}>
      <label>
        <input
          type="checkbox"
          {...field}
          value={value}
          checked={field.value || false}
          disabled={disabled}
        />
        {label && <span>{label}</span>} {required && <abbr title="обязательное поле">*</abbr>}
      </label>
      {error && <div className="field__error">{error.message}</div>}
    </div>
  );
};


const CheckboxField = (props) => {
  return (
    <FieldController
      {...props}
      render={({ field, className, placeholder, error }) => {
        return (
          <Checkbox className={className} field={field} {...props} />
        )
      }}
    />
  );
};

CheckboxField.Checkbox = Checkbox;

export default CheckboxField;
