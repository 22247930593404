import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  detail: null,
};

export const cardSlice = createSlice({
  initialState,
  name: 'cardSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getCardDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
  },
});

export default cardSlice.reducer;
