import { NavLink } from "react-router-dom"

import Loader from 'components/Loader'

import "./styles.css"


const PassCard = ({ title, url, info, icon, isLoading, style }) => {
  return (
    <NavLink className="passCard" to={url} style={style}>
      <div className="passCard__header">
        <div className="passCard__title">{title}</div>
        <div className="passCard__info">{info}</div>
      </div>
      {icon && <img className="passCard__icon" src={icon} alt="" />}
      <svg className="passCard__qricon" >
        <use xlinkHref="/img/icons.svg#qr"></use>
      </svg>

      {isLoading && <Loader isLoading={isLoading} />}
    </NavLink>
  );
};

export default PassCard;
