import { useMemo, useContext } from 'react'
import { Controller } from "react-hook-form"

import { Form } from "components/forms"

import "./styles.css"


const FieldController = ({ name, label, placeholder, defaultValue, required, minLength, maxLength, render }) => {
  const { control, formState, status } = useContext(Form.FormContext);
  const error = formState?.errors[name];

  const className = useMemo(() => {
    let _cls = "field";
    if (error) _cls += " field_withError";
    if (status?.isLoading) _cls += " field_loading";
    return _cls;
  }, [error, status?.isLoading])

  const title = label || placeholder || '';
  const _placeholder = useMemo(() => {
    if (label) return placeholder || '';
    if (placeholder) {
      if (required) return placeholder + ' *'
      return placeholder;
    }
    return '';
  }, [placeholder, label, required]);

  if (!control) return null;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      rules={{ 
        required: required ? `«${title}» обязательное поле` : false,
        minLength: minLength ? {value: minLength, message: `Должно быть ${minLength} цифр`}: false,
        maxLength: maxLength ? {value: maxLength, message: `Должно быть ${maxLength} цифр`}: false,
      }}
      render={({ field }) => render({ field, className, placeholder: _placeholder, error })}
    />
  );
};

export default FieldController;
