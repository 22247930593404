import { useDispatch } from 'react-redux'

import { appActions } from 'redux/features/appSlice'
import Button from "components/Button"
import SvgIcon from "components/SvgIcon"

import "./styles.css"


const FormSuccess = ({ children, title, btnTitle, status, onClose }) => {
  const dispatch = useDispatch();

  let cls = "formSuccess";

  const close = () => {
    dispatch(appActions.toggleModal(false));
    if (onClose) onClose();
  }

  return (
    <div className={cls}>
      <div className="formSucess__icon">
        <SvgIcon icon="check" />
      </div>
      {title && <div className="formSucess__title">{title}</div>}

      <div className="formSucess__text">{children}</div>

      <div className="formSucess__footer">
        <Button title={btnTitle} onClick={close} />
      </div>
    </div>
  );
};

export default FormSuccess;
