import FieldController from "../FieldController"

import "./styles.css"


const TextareaField = (props) => {
  return (
    <FieldController
      {...props}
      render={({ field, className, placeholder, error }) => {
        return (
          <div className={`${className} textareaField`}>
            {props.label && <label>{props.label} {props.required && <abbr title="обязательное поле">*</abbr>}</label>}
            <textarea placeholder={placeholder} {...field} disabled={props.disabled}/>
            {error && <div className="field__error">{error.message}</div>}
          </div>
        )
      }}
    />
  );
};

export default TextareaField;
