import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { api } from './api/api'
import appSlice from './features/appSlice'

import authSlice from 'models/auth/authSlice'
import newsSlice from 'models/news/newsSlice'
import hallSlice from 'models/hall/hallSlice'
import hallRequestSlice from 'models/hallRequest/hallRequestSlice'
import hallAccessSlice from 'models/hallAccess/hallAccessSlice'
import eventSlice from 'models/event/eventSlice'
import tenantSlice from 'models/tenant/tenantSlice'
import tenantCategorySlice from 'models/tenantCategory/tenantCategorySlice'
import buildingSlice from 'models/building/buildingSlice'
import buildingLevelSlice from 'models/buildingLevel/buildingLevelSlice'

import searchSlice from 'models/search/searchSlice'
import cardSlice from 'models/card/cardSlice'
import cardRequestSlice from 'models/cardRequest/cardRequestSlice'

import parkingSlice from 'models/parking/parkingSlice'
import parkingPassSlice from 'models/parkingPass/parkingPassSlice'

import feedbackSlice from 'models/feedback/feedbackSlice'


const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: appSlice,

  auth: authSlice,
  news: newsSlice,
  hall: hallSlice,
  hallRequest: hallRequestSlice,
  hallAccess: hallAccessSlice,
  event: eventSlice,
  tenant: tenantSlice,
  tenantCategory: tenantCategorySlice,
  building: buildingSlice,
  buildingLevel: buildingLevelSlice,
  
  search: searchSlice,
  card: cardSlice,
  cardRequest: cardRequestSlice,

  parking: parkingSlice,
  parkingPass: parkingPassSlice,

  feedback: feedbackSlice,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, api.middleware]
})

export const persistor = persistStore(store)
