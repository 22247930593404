import SvgIcon from "components/SvgIcon"

import "./styles.css"


const Tag = ({ children, onDelete = null }) => {
  const onClick = () => {
    onDelete();
  }

  return (
    <div className="tag" onClick={onClick}>
      {children} {onDelete && <div className="tag__icon"><SvgIcon icon="close" /></div>}
    </div>
  );
};

export default Tag;
