import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom"
import Slider from "react-slick"

import { useLazyGetListQuery } from 'models/tenantCategory/api'

import "./styles.css"


const TenantCategorySlider = () => {
  const tenantCategoryList = useSelector(state => state.tenantCategory.list);

  const location = useLocation();
  const navigate = useNavigate();

  const currentCategorySlug = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('category');
  }, [location.search]);

  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    getObjectList();
    // eslint-disable-next-line
  }, []);

  const onCategoryClick = (slug) => {
    if (currentCategorySlug === slug) {
      navigate(`/tenant/`, { replace: true });
    } else {
      navigate(`/tenant/?category=${slug}`, { replace: true });
    }
  }

  return (
    <div className="categorySlider">
      <Slider className="categorySliderSlick" dots={false} arrows={true} infinite={false} centerMode={false} variableWidth={true} speed={500} slidesToShow={1}>
        {tenantCategoryList.map(category => {
          let className = 'categoryCard';
          if (category.slug === currentCategorySlug) {
            className += ' active';
          }
          return (
            <div key={`category${category.slug}`}>
              <div className={className} onClick={() => onCategoryClick(category.slug)}>
                <img className="categoryCard__img" src={category.icon[0]?.url} alt={category.name} />
                <div className="categoryCard__title">{category.name}</div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  );
};

export default TenantCategorySlider;
