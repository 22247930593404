import { useState } from "react"
import { useForm } from "react-hook-form"

import { loadStatus } from "utils/network"
import { Form, ReadonlyFieldset, ReadonlyField, Fieldset, TextField } from "components/forms"
import Button from "components/Button"

import "./styles.css"


const FeedbackRequestForm = () => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm();
  const [status, setStatus] = useState(loadStatus(loadStatus.notAsked));

  // const navigate = useNavigate();

  const onSubmit = data => {
    if (!status.isLoading) {
      setStatus(loadStatus(loadStatus.loading));
      console.log(data);
      setTimeout(() => {
        setStatus(loadStatus(loadStatus.loaded));
      }, 2000);
    }
  }

  const formFooter = (
    <Button type="submit" title="Оставить заявку" size="lg" loading={status.isLoading} />
  );

  const formSucess = null;/*(
    <FormSuccess
      title="Ваша заявка принята"
      btnTitle="Перейти к заявке"
      onClose={() => {
        reset();
        navigate("/feedback/history/3/", { replace: true });
      }}
    >
      Номер вашей заявки <b className="mark">3</b>
    </FormSuccess>
  );*/

  const formText = (
    <>
      <div>Опишите вашу задачу и&nbsp;оставьте заявку на&nbsp;её исполнение</div>
      <div>* - поля, обязательные для заполнения</div>
    </>
  );

  return (
    <Form
      status={status}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      reset={reset}
      formText={formText}
      formFooter={formFooter}
      formSucess={formSucess}
    >
      <ReadonlyFieldset>
        <ReadonlyField value="Кантимеров Владлен" />
        <ReadonlyField value="+7 962 532-33-99" />
      </ReadonlyFieldset>

      <Fieldset>
        <TextField
          name="text"
          label="Текст обращения"
          placeholder="Введите текст обращения"
          control={control}
          errors={errors}
          required={true}
          loading={status.isLoading}
        />
      </Fieldset>
    </Form>
  );
};

export default FeedbackRequestForm;
