import Coupon from '../Coupon'

import './styles.css'


const CardBenefits = ({ title, benefits }) => {
  return (
    <div className="cardBenefits">
      {title && <div className="benefits__title">{title}</div>}
      {benefits.map(benefit => {
        const tenant = benefit.tenant;
        let discounts = [{ val: benefit.value, lbl: benefit.valueLabel }];
        if (benefit.value2) {
          discounts.push({ val: benefit.value2, lbl: benefit.value2Label });
        }
        return (
          <Coupon
            key={`coupon${benefit.id}`}
            title={tenant.name}
            logo={tenant.icon[0]?.url}
            text={benefit.text}
            discounts={discounts}
          />
        )
      })}
    </div>
  );
};

export default CardBenefits;
