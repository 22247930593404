import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form"

import AuthApi from 'models/auth/api'
import { Form, Fieldset, TextField } from "components/forms"
import Button from "components/Button"

import './styles.css'


const UserEditForm = () => {
  const { control, handleSubmit, reset, formState, setValue, setError, clearErrors } = useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(state => state.auth.user);
  const [updateUser, updateUserProps] = AuthApi.useUpdateUserMutation();

  const onSubmit = data => {
    if (!updateUserProps.isLoading) {
      console.log(data);
      updateUser(data);
    }
  }

  const onError = (errors, e) => {
    console.log(errors, e);
  }

  useEffect(() => {
    if (updateUserProps.isSuccess) {
      navigate(location.state?.from || '/profile/');
    }
  }, [updateUserProps.isSuccess, location.state?.from, navigate]);

  //const phone = user?.phone && !user?.phone.startsWith('@') ? user?.phone : '';

  return (
    <Form
      className="loginForm"
      control={control}
      formState={formState}
      onSubmit={onSubmit}
      onError={onError}
      handleSubmit={handleSubmit}
      reset={reset}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
      status={{
        isLoading: updateUserProps.isLoading,
        isSuccess: updateUserProps.isSuccess,
        isError: updateUserProps.isError,
        errorData: updateUserProps.error?.data,
        successData: updateUserProps.data,
      }}
      formFooter={(status) => (
        <Button type="submit" title="Сохранить" size="md" loading={status.isLoading} />
      )}
      initials={{ ...user }}
    >
      <div className="formHeader">
        <div className="formHeaderTitle">Укажите ваше имя и фамилию</div>
      </div>

      <Fieldset>
        <TextField
          name="first_name"
          //label="Email"
          placeholder="Введите ваше имя"
          required={true}
        />

        <TextField
          name="last_name"
          //label="Email"
          placeholder="Введите вашу фамилию"
          required={true}
        />
      </Fieldset>
    </Form>
  );
};

export default UserEditForm;
