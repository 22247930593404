import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

import UserEditForm from 'models/auth/components/UserEditForm'
import { Page } from 'layout'
import SvgIcon from "components/SvgIcon"

import './styles.css'


const ProfileEditPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(state => state.auth.user);

  const hasEmptyFields = !user.first_name || !user.last_name;

  const onClose = () => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      navigate(-1)
    }
  }

  return (
    <Page
      className="loginPage"
      hideNav={true}
    >
      {!hasEmptyFields &&
        <div className="loginPageClose" onClick={onClose}>
          <SvgIcon icon="close" />
        </div>
      }

      <div className="loginPanel">
        <UserEditForm />
      </div>
    </Page>
  );
};

export default ProfileEditPage;
