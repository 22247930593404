import { api } from 'redux/api/api'


const TenantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTenantList: builder.query({
      query: (params = null) => ({
        url: 'tenants/tenant/',
        params: params,
      }),
    }),
    getTenantSimpleList: builder.query({
      query: (params = null) => ({
        url: 'tenants/tenant/',
        params: params,
      }),
    }),
    getTenantDetail: builder.query({
      query: (id) => ({
        url: `tenants/tenant/${id}/`,
      }),
    }),
    getTenantForEdit: builder.query({
      query: (id) => ({
        url: `tenants/tenant/${id}/`,
      }),
    }),

    updateTenant: builder.mutation({
      query(values) {
        const url = values.id ? `tenants/tenant/${values.id}/`: 'tenants/tenant/';
        const method = values.id ? `PUT`: 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
    deleteTenant: builder.mutation({
      query(id) {
        return {
          url: `tenants/tenant/${id}/`,
          method: 'DELETE',
        };
      },
    }),
    batchDeleteTenant: builder.mutation({
      query(selected) {
        return {
          url: 'tenants/tenant/delete/',
          method: 'POST',
          body: { selected },
        };
      },
    }),
  }),
});

export default TenantApi;

const {
  useLazyGetTenantListQuery,
  useLazyGetTenantSimpleListQuery,
  useLazyGetTenantDetailQuery,
  useLazyGetTenantForEditQuery,
  useUpdateTenantMutation,
  useDeleteTenantMutation,
  useBatchDeleteTenantMutation,
} = TenantApi;

export {
  useLazyGetTenantListQuery as useLazyGetListQuery,
  useLazyGetTenantSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetTenantDetailQuery as useLazyGetDetailQuery,
  useLazyGetTenantForEditQuery as useLazyGetForEditQuery,
  useUpdateTenantMutation as useUpdateMutation,
  useDeleteTenantMutation as useDeleteMutation,
  useBatchDeleteTenantMutation as useBatchDeleteMutation,
};
