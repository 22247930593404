import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getFullName } from 'utils/helpers'
import { appActions } from 'redux/features/appSlice'
import UserTop from 'models/auth/components/UserTop'
import SearchTop from 'models/search/components/SearchTop'

import "./styles.css"


const Header = ({ title, showBottom }) => {
  const dispatch = useDispatch();
  const showDrawer = useSelector(state => state.app.showDrawer);
  const user = useSelector(state => state.auth.user);

  const [bottomOpacity, setBottomOpacity] = useState(1);
  const [headerTop, setHeaderTop] = useState(0);
  const [titleY, setTitleY] = useState(0);

  const maxHeaderY = 100;

  useEffect(() => {
    const handleScroll = event => {
      let hy = window.scrollY;
      if (hy >= maxHeaderY) {
        hy = maxHeaderY;
      } else if (hy <= 0) {
        hy = 0;
      }
      if (hy >= maxHeaderY - 42) {
        setBottomOpacity(0);
        setTitleY(hy - maxHeaderY + 42);
      } else {
        setBottomOpacity(1);
        setTitleY(0);
      }
      setHeaderTop(hy);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [maxHeaderY]);

  let cls = "header";
  if (showBottom) cls += " header_withBottom";

  let style = {
    transform: `translateY(-${headerTop}px)`,
  }

  let styleTop = {
    transform: `translateY(${headerTop}px)`,
  }

  let stylebottom = {
    opacity: bottomOpacity,
  }

  let styleTitle = {
    transform: `translateY(${titleY}px)`,
  }

  return (
    <div className={cls} style={style}>
      <div className="header__title" style={styleTitle}>{title || getFullName(user)}</div>

      <div className="header__top" style={styleTop}>
        <div className="header__topBtn" onClick={() => dispatch(appActions.toggleDrawer(!showDrawer))}>
          <span className="navBtn__icon">
            <span></span>
          </span>
        </div>

        <UserTop />
      </div>

      <SearchTop style={stylebottom} />
    </div>
  );
};

export default Header;
