import { api } from 'redux/api/api'


const AuthApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query(data) {
        return {
          url: 'auth/',
          method: 'POST',
          body: data,
        };
      },
    }),

    loginOauth: builder.query({
      query: ({ service, params }) => ({
        url: `auth/${service}/${params}`,
      }),
    }),

    loginUserOTP: builder.mutation({
      query(data) {
        return {
          url: 'auth/otp/',
          method: 'POST',
          body: data,
        };
      },
    }),

    logoutUser: builder.mutation({
      query(token) {
        return {
          url: 'auth/logout/',
          method: 'POST',
          body: { token },
        };
      },
    }),

    tokenRefresh: builder.mutation({
      query(token) {
        return {
          url: 'auth/refresh/',
          method: 'POST',
          body: { token },
        };
      },
    }),

    updateUser: builder.mutation({
      query(data) {
        return {
          url: '/user/user/',
          method: 'PUT',
          body: data,
        };
      },
    }),
  }),
});

export default AuthApi;
