import { useEffect } from 'react'

import { useLazyGetDetailQuery } from 'models/card/api'
import CardRequestForm from 'models/cardRequest/components/CardRequestForm'
import CardRequestExistingForm from 'models/cardRequest/components/CardRequestExistingForm'
import { Page, TopBar } from 'layout'

import './styles.css'


const ClubCardRequestPage = ({ existing = false }) => {
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail();
    // eslint-disable-next-line
  }, []);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="Клубная карта" showBack={true} />}
      hideNav={true}
      isLoading={getObjectDetailProps.isLoading}
    >
      {existing ? <CardRequestExistingForm /> : <CardRequestForm />}
    </Page>
  );
};

export default ClubCardRequestPage;
