import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form"

import AuthApi from 'models/auth/api'
import { Form, Fieldset, TextField } from "components/forms"
import Button from "components/Button"

import './styles.css'


const OTPLoginForm = () => {
  const { control, handleSubmit, reset, formState, setError, clearErrors } = useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const [loginUserOTP, loginProps] = AuthApi.useLoginUserOTPMutation();

  const [step, setStep] = useState(1);

  const userId = loginProps.data?.userId;

  const onSubmit = data => {
    if (!loginProps.isLoading) {
      if (step === 1) {
        loginUserOTP({
          'step': 1,
          ...data,
        });
      } else if (userId) {
        loginUserOTP({
          'step': 2,
          'userId': userId,
          ...data,
        });
      }
    }
  }

  const onError = (errors, e) => {
    console.log(errors, e);
  }

  useEffect(() => {
    if (userId && step !== 2) {
      setStep(2);
      reset();
    } else if (!userId && step !== 1) {
      setStep(1);
      reset();
    }
  }, [step, userId, reset]);

  useEffect(() => {
    if (token) {
      if (!user?.first_name || !user?.last_name) {
        navigate('/profile/edit/', { state: { from: location.state?.from || '/', replace: true } });
      } else {
        navigate(location.state?.from || '/', { replace: true });
      }
    }
  }, [token, user?.first_name, user?.last_name, location.state?.from, navigate]);

  return (
    <Form
      className="loginForm"
      control={control}
      formState={formState}
      onSubmit={onSubmit}
      onError={onError}
      handleSubmit={handleSubmit}
      reset={reset}
      setError={setError}
      clearErrors={clearErrors}
      status={{
        isLoading: loginProps.isLoading,
        isSuccess: loginProps.isSuccess,
        isError: loginProps.isError,
        errorData: loginProps.error?.data,
        successData: loginProps.data,
      }}
      formFooter={(status) => (
        <>
          <Button type="submit" title={step === 1 ? "Получить код" : "Войти"} size="md" loading={status.isLoading} />

          {step === 1 &&
            <div className="altLogin">
              <div className="altLoginOr">или</div>

              <Link className="altLoginLink" to="/login/" state={location.state} replace={true} title="Войти с паролем" size="md">Войти с паролем</Link>
            </div>}
        </>

      )}
      formPrivacy={() => (
        step === 2 &&
        <>Нажимая кнопку «Получить код», вы соглашаетесь c&nbsp;<Link to="/privacy/">условиями политики</Link> конфиденциальности</>
      )}
    >
      <div className="formHeader">
        <div className="formHeaderTitle">Вход или регистрация</div>
        <div className="formHeaderSubtitle">Нужен только номер телефона или&nbsp;email</div>
      </div>

      <Fieldset>
        {step === 1 ?
          <TextField
            name="phoneOrEmail"
            //label="Email"
            placeholder="Введите номер телефона или email"
            required={true}
          />
          :
          <TextField
            name="otpToken"
            //label="Email"
            placeholder="Введите код"
            required={true}
          />
        }
      </Fieldset>
    </Form>
  );
};

export default OTPLoginForm;
