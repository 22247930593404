import { api } from 'redux/api/api'


const CardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardDetail: builder.query({
      query: () => ({
        url: `card/card/`,
      }),
    }),
  }),
});

export default CardApi;

const {
  useLazyGetCardDetailQuery,
} = CardApi;

export {
  useLazyGetCardDetailQuery as useLazyGetDetailQuery,
};

