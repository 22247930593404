import { useLocation, useNavigate } from "react-router-dom"

import PassLoginForm from 'models/auth/components/PassLoginForm'
import SvgIcon from "components/SvgIcon"

import './styles.css'


const AuthPassLoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log('location1', location);

  const onBack = () => {
    navigate('/login/', { state: location.state })
  }

  const onClose = () => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      navigate(-1)
    }
  }

  return (
    <div className="loginPage">
      <div className="loginPageBack" onClick={onBack}>
        <SvgIcon icon="angleLeft" />
      </div>

      <div className="loginPageClose" onClick={onClose}>
        <SvgIcon icon="close" />
      </div>

      <div className="loginPanel">
        <PassLoginForm />
      </div>
    </div>
  );
};

export default AuthPassLoginPage;
