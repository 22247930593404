import React from 'react'

import './styles.css'


const DetailText = ({ text, style }) => {
  return (
    <div className="detailText" style={style} dangerouslySetInnerHTML={{ __html: text }}/>
  );
};

export default DetailText;
