import { useRef, useEffect, useState } from 'react'
import { NavLink } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import Loader from 'components/Loader'
import { useLazySearchQuery } from 'models/search/api'
import { searchActions } from 'models/search/searchSlice'

import "./styles.css"


const Suggestions = ({ suggestions, onServiceClick }) => {
  return (
    <>
      {suggestions?.tenantServices.map((service) => (
        <div key={`service${service.slug}`} className="searchBox__suggestion">
          <NavLink className="suggestionLink" to={`/tenant/?services=${service.slug}`} replace={true} onClick={() => onServiceClick(service)}>
            <div className="suggestionLink__body">
              <div className="suggestionLink__title">#{service.name}</div>
              <div className="suggestionLink__note">Компании, оказывающие услугу «{service.name}»</div>
            </div>
          </NavLink>
        </div>
      ))
      }

      {suggestions?.tenants.map((tenant) => (
        <div key={`tenant${tenant.slug}`} className="searchBox__suggestion">
          <NavLink className="suggestionLink" to={`/tenant/${tenant.slug}/`}>
            {tenant.icon[0] &&
              <div className="suggestionLink__icon">
                <img className="suggestionLink__logo" src={tenant.icon[0]?.url} alt={tenant.name} />
              </div>
            }
            <div className="suggestionLink__body">
              <div className="suggestionLink__title">{tenant.name}</div>
            </div>
          </NavLink>
        </div>
      ))
      }
    </>
  )
}


const TenantSearchBox = ({ placeholder, searchText }) => {
  const inputRef = useRef();
  let cls = "searchBox";

  const dispatch = useDispatch();

  const [search] = useLazySearchQuery();
  const results = useSelector(state => state.search.results);

  const [showSuggestions, setShowSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const clearResults = () => {
    dispatch(searchActions.clearResults(false))
  }

  useEffect(() => {
    if (!searchText) {
      inputRef.current.value = '';
      setShowSuggestions(false);
      setLoading(false);
      clearResults();
    } else {
      inputRef.current.value = searchText;
      search({ q: searchText, mode: 'tenant' });
      setLoading(true);
    }

    // eslint-disable-next-line
  }, [searchText]);

  const onFocus = () => {
    setShowSuggestions(true);
  }

  const onBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 100)
  }

  const handleKeyPress = (event) => {
    const value = event.target.value;

    if (value.length >= 2) {
      search({ q: value, mode: 'tenant' });
      setShowSuggestions(true);
      setLoading(true);
    } else {
      clearResults();
      setShowSuggestions(false);
      setLoading(false);
    }
  }

  const onServiceClick = (service) => {
    inputRef.current.value = service.name;
    setLoading(true);
    setShowSuggestions(false);
    search({ q: service.name, mode: 'tenant' });
  }

  const isEmpty = results?.tenantServices?.length === 0 && results?.tenants?.length === 0;

  return (
    <div className={cls}>
      <button className="searchBox__btn">
        <svg className="icon" >
          <use xlinkHref="/img/icons.svg#search"></use>
        </svg>
      </button>
      <input
        ref={inputRef}
        className="searchBox__inp"
        type="text"
        name="search2"
        defaultValue={searchText}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={handleKeyPress}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      />

      {showSuggestions &&
        <div className="searchBox__suggestions">
          {!results && loading ?
            <div className="searchBox__loading"><Loader theme="dark" isLoading={true} /></div> :
            isEmpty ?
              <div className="searchBox__empty">Ничего не найдено</div> :
              <Suggestions suggestions={results} onServiceClick={onServiceClick} />
          }
        </div>
      }
    </div >
  );
};

export default TenantSearchBox;
