import { useNavigate, useLocation } from "react-router-dom"

import "./styles.css"


const TopBar = ({ title, showBack, isModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  let cls = "topBar";
  if (isModal) cls += " mode_modal";

  const onBack = () => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      navigate(-1)
    }
  }

  return (
    <div className={cls}>
      {showBack || isModal ?
        <button className="backBtn" onClick={onBack}>
          <svg className="backBtn__icon" >
            <use xlinkHref="/img/icons.svg#arrowLeft"></use>
          </svg>
        </button>
        :
        null
      }
      <div className="topBar__title">{title}</div>
    </div>
  );
};

export default TopBar;
