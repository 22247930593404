import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: 0,
  actualCount: 0,

  detailForEdit: null,
  detail: null,
};

export const hallRequestSlice = createSlice({
  initialState,
  name: 'hallRequestSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getHallRequestList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;

        const originalArgs = action.meta.arg.originalArgs;
        if (originalArgs?.actual) {
          state.actualCount = action.payload.count;
        }
      })
      .addMatcher(api.endpoints.getHallRequestList.matchRejected, (state, action) => {
        state.list = [];
        state.allCount = 0;
        state.actualCount = 0;
      })

      .addMatcher(api.endpoints.getHallRequestDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(api.endpoints.getHallRequestForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getHallRequestForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })
      .addMatcher(api.endpoints.updateHallRequest.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default hallRequestSlice.reducer;
