import { useMemo } from 'react'
import { useLocation } from "react-router-dom"
import { useSelector } from 'react-redux'

import HallRequestForm from 'models/hallRequest/components/HallRequestForm'

import { Page, TopBar } from 'layout'

import './styles.css'


const HallOrderPage = () => {
  let location = useLocation();
  
  const [hallId, eventDate, eventTime] = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const _hallId = parseInt(searchParams.get('hall')) || 0;
    let _eventDate = searchParams.get('eventDate');
    let _eventTime = searchParams.get('eventTime');
    return [_hallId, _eventDate, _eventTime]
  }, [location.search]);

  const user = useSelector(state => state.auth.user);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="Заявка на аренду зала" showBack={true} />}
      hideNav={true}
    >
      <HallRequestForm user={user} initials={{ hall: hallId, eventDate, eventTime }} />
    </Page>
  );
};

export default HallOrderPage;
