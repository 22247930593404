import { useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

import { useLazyGetDetailQuery } from 'models/hallAccess/api'
import { Page, TopBar } from 'layout'
import QRCodePass from 'components/QRCodePass'
import HallAccessDetail from 'models/hallAccess/components/HallAccessDetail'

import './styles.css'


const HallAccessPassPage = () => {
  let { accessId } = useParams();
  const accessDetail = useSelector(state => state.hallAccess.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail(accessId);
    // eslint-disable-next-line
  }, [accessId]);

  return (
    <Page
    className="dark"
    renderHeader={(pageRef) => <TopBar title="Ключ доступа к залу" showBack={true} />}
      hideNav={true}
      isLoading={getObjectDetailProps.isLoading}
    >
      <HallAccessDetail accessDetail={accessDetail} />
      
      {accessDetail?.code &&
        <QRCodePass
          code={accessDetail?.code}
          title="Ключ доступа к залу"
          name={accessDetail?.hall?.name}
          startTitle="Дата начала"
          endTitle="Дата завершения"
          pass={{
            entryDate: accessDetail?.eventStart,
            freeDate: accessDetail?.eventEnd
          }}
        />}
    </Page>
  );
};

export default HallAccessPassPage;
