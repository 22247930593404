import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,
  actualCount: null,

  detailForEdit: null,
  detail: null,
};

export const cardRequestSlice = createSlice({
  initialState,
  name: 'cardRequestSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getCardRequestList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;

        const originalArgs = action.meta.arg.originalArgs;
        if (originalArgs?.actual) {
          state.actualCount = action.payload.count;
        }
      })
      .addMatcher(api.endpoints.getCardRequestDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(api.endpoints.getCardRequestForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getCardRequestForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })
      .addMatcher(api.endpoints.updateCardRequest.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default cardRequestSlice.reducer;
