import './styles.css'


const PlaceInfo = ({ width, height, place, hall, tenant }) => {
  const item = hall || tenant || place;
  const position = place.schemeInfoPosition || [0, 0];

  const maxWidth = place.schemeInfoRotate === 0 ? width : height;

  let title2 = '';
  if (place.status === 'freeToRent' || place.status === 'free') {
    title2 = 'Сдаётся в аренду';
  } else if (place.status === 'freeToBuy') {
    title2 = 'Продаётся';
  }

  const innerStyle = {
    transform: `translate(${position[0]}px, ${position[1]}px) rotate(-${place.schemeInfoRotate}deg)`, 
    maxWidth: `${maxWidth}px`, 
    width: `${maxWidth}px`
  };

  return (
    <div className="schemePlaceInfo">
      <div className="schemePlaceInfo__inner" style={innerStyle}>
        {place?.schemeShowIcon && item.icon && item.icon.length > 0 ?
          <div className="schemePlaceInfo__logo" style={{ width: `${place.schemeIconWidth}px` }}>
            <img src={item.icon[0].url} alt="" />
          </div>
          :
          <div className="schemePlaceInfo__title" style={{ width: `${place.schemeTitleWidth}px`, fontSize: `${place.schemeTitleFontSize}px` }}>
            <div>{tenant?.name || hall?.name}</div>
            {!place.isUsed && title2 && <div className="schemePlaceInfo__titleSub">{title2}</div>}
          </div>
        }
      </div>
    </div>
  )
}

export default PlaceInfo
