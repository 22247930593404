import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  tariffs: [
    {
      id: 1,
      name: 'Разовый',
      text: 'Действителен для одного заезда/выезда',
      price: 200,
    },
    {
      id: 2,
      name: '10-ка',
      text: 'Действителен для 10 заездов/выездов',
      price: 1800,
    },
    {
      id: 3,
      name: '20-ка',
      text: 'Действителен для 20 заездов/выездов',
      price: 3400,
    },
    {
      id: 4,
      name: '30-ка',
      text: 'Действителен для 30 заездов/выездов',
      price: 4200,
    },
  ],
  tariffDetail1: {
    id: 1,
    name: 'Разовый',
    text: 'Разовый въезд на парковку',
    price: 200,
    count: 1,
  },
  tariffDetail2: {
    id: 2,
    name: '10-ка',
    text: '10 въездов на парковку',
    price: 1800,
    count: 10,
  },
  tariffDetail3: {
    id: 3,
    name: '20-ка',
    text: '20 въездов на парковку',
    price: 3400,
    count: 20,
  },
  tariffDetail4: {
    id: 4,
    name: '30-ка',
    text: '30 въездов на парковку',
    price: 4200,
    count: 30,
  },
};


const parkingSlice = createSlice({
  name: 'parkingSlice',
  initialState,
  reducers: {},
});

export default parkingSlice.reducer;
