import { api } from 'redux/api/api'


const BuildingLevelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuildingLevelList: builder.query({
      query: (params = null) => ({
        url: 'building/level/',
        params: params,
      }),
    }),
    getBuildingLevelDetail: builder.query({
      query: (id) => ({
        url: `building/level/${id}/`,
      }),
    }),
  }),
});

export default BuildingLevelApi;

const {
  useLazyGetBuildingLevelListQuery,
  useLazyGetBuildingLevelDetailQuery,
} = BuildingLevelApi;

export {
  useLazyGetBuildingLevelListQuery as useLazyGetListQuery,
  useLazyGetBuildingLevelDetailQuery as useLazyGetDetailQuery,
};

