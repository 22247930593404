import React from 'react'

import { Page, TopBar, Panel } from 'layout'
import FeedbackRequestList from 'models/feedback/components/FeedbackRequestList'

import './styles.css'


const FeedbackRequestListPage = () => {
  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="История обращений" showBack={true} />}
      hideNav={true}
    >
      <Panel title="Ваши заявки на сервисное обслуживание" padding="0">
        <FeedbackRequestList/>
      </Panel>
    </Page>
  );
};

export default FeedbackRequestListPage;
