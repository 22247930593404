import "./styles.css"


const ReadonlyFieldset = ({ children }) => {
  return (
    <div className="readonlyFieldset">
      {children}
    </div>
  );
};

export default ReadonlyFieldset;
