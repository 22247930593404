import { useNavigate, useParams } from "react-router-dom"

import { Page, TopBar } from 'layout'
import BuildingSelect from 'models/building/components/BuildingSelect'
import BuildingScheme from 'models/buildingLevel/components/BuildingScheme'


const SchemePage = () => {
  const { buildingSlug } = useParams();
  const navigate = useNavigate();

  const onLoaded = (buildingList) => {
    if (!buildingSlug && buildingList.length) {
      navigate(`/scheme/${buildingList[0].slug}/`, { replace: true })
    }
  };

  return (
    <Page
      renderHeader={() => (
        <TopBar
          title={
            <>
              <div style={{ marginRight: '12px' }}>Корпус:</div>
              <BuildingSelect currentSlug={buildingSlug} onLoaded={onLoaded} />
            </>
          }
          showBack={true}
        />
      )}
      isFixed={false}
      hideNav={true}
    >
      {buildingSlug && <BuildingScheme buildingSlug={buildingSlug} />}
    </Page>
  );
};

export default SchemePage;
