import { useEffect, useState, useRef } from 'react'

import './styles.css'


const SchemeLevelNav = ({ levels, currentLevel, onLevelChange }) => {
  const ref = useRef(null);
  const dotRef = useRef();
  const [dx, setDx] = useState(24);
  const [dotX, setDotX] = useState(dx * currentLevel);

  const resize = () => {
    if (levels.length > 0) {
      const width = ref.current ? ref.current?.offsetWidth - 40 : 300;
      setDx(24 + (width - 24 * levels.length) / (levels.length - 1));
    }
  }

  useEffect(() => {
    resize();

    const onWindowResize = (e) => {
      resize();
    }

    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    setDotX(dx * currentLevel);
  }, [currentLevel, dx]);

  const changeLevel = (level, index) => {
    setDotX(dx * index);

    onLevelChange && onLevelChange(index);

    dotRef.current.classList.add("animate");
    setTimeout(function () {
      dotRef.current.classList.remove("animate")
    }, 1000)
  }

  if (levels.length === 0) return null;

  return (
    <div ref={ref} className="schemeNav">
      <div ref={dotRef} className="schemeNavDot" style={{ transform: `translate(${dotX}px, -50%)` }} />
      {levels.map((level, index) => {
        //const revIndex = levels.length - index - 1;
        let cls = "schemeNavItem";
        if (index === currentLevel) {
          cls += " active";
        }
        return (
          <div key={`level${level.id}`} className={cls} onClick={() => changeLevel(level, index)}>
            <div>{level.name}</div>
          </div>
        )
      })}
    </div>
  );
}

export default SchemeLevelNav
