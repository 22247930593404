import { NavLink, useLocation } from "react-router-dom"
import { useDispatch } from 'react-redux'

import { appActions } from 'redux/features/appSlice'

import "./styles.css"


function Drawer() {
  const dispatch = useDispatch();
  const location = useLocation();

  const navItems = [
    {
      path: '/events/',
      title: 'События',
      icon: '/img/icons/event.png'
    },
    {
      path: '/news/',
      title: 'Новости',
      icon: '/img/icons/event.png'
    },
    {
      path: '/tenant/',
      title: 'Арендаторы',
      icon: '/img/icons/conference-room.png'
    },
    {
      path: '/hall/',
      title: 'Залы',
      icon: '/img/icons/conference-room.png'
    },
    /*{
      path: '/parking/',
      title: 'Парковка',
      icon: '/img/icons/park.png'
    },*/
    {
      path: '/profile/',
      title: 'Профиль',
      icon: '/img/icons/user.png'
    },
    {
      path: '/feedback/',
      title: 'Связь с администрацией',
      icon: '/img/icons/conference-room.png'
    },
  ];

  const onNavClick = (e) => {
    dispatch(appActions.toggleDrawer(false))
  }

  return (
    <div className="drawer">
      <div className="drawerLogo"></div>

      <div className="drawerNav">
        {navItems.map((item, i) => {
          let cls = "drawerNav__item";
          if (location.pathname === item.path || (location.pathname.startsWith(item.path) && item.path !== '/')) {
            cls += " active";
          }
          return (
            <NavLink
              key={`nav${i}`}
              to={item.path}
              className={cls}
              end={false}
              onClick={onNavClick}
            >
              <span className="drawerNav__itemTitle">{item.title}</span>
            </NavLink>
          )
        })}
      </div>
    </div>
  );
}

export default Drawer;
