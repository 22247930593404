import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/buildingLevel/api'
import BuildingPlacePopup from 'models/buildingLevel/components/BuildingPlacePopup'
import Scheme from 'components/Scheme'

import './styles.css'


const BuildingScheme = ({ buildingSlug }) => {
  const buildingLevelList = useSelector(state => state.buildingLevel.list);

  const [getObjectList, getObjectListProps] = useLazyGetListQuery();

  useEffect(() => {
    getObjectList({ building: buildingSlug });
    // eslint-disable-next-line
  }, [buildingSlug]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupObj, setPopupObj] = useState({});

  const onOpenPlace = (place, hall, tenant) => {
    const isClosed = place.status === 'closed' || (place.status === 'booked' && !tenant && !hall);
    if (isClosed) return;
    setShowPopup(true);
    setPopupObj({ place, hall, tenant });
  }

  return (
    <>
      <Scheme
        title={buildingSlug === 'darkside' ? 'ул. Генерала Горбатова, 2': 'ул. Менделеева, 134/7'}
        levels={buildingLevelList}
        isLevelsLoading={getObjectListProps.isLoading}
        selectedPlace={popupObj?.place}
        onOpenPlace={onOpenPlace}
      />

      <BuildingPlacePopup
        show={showPopup}
        onClose={() => {
          setShowPopup(false)
          setPopupObj({});
        }}
        {...popupObj}
      />
    </>
  );
}

export default BuildingScheme
