import { useEffect, createContext } from "react"
import { useDispatch } from 'react-redux'

import { isDict } from "utils/helpers"
import { appActions } from 'redux/features/appSlice'
import FormError from './FormError'

import "./styles.css"

const FormContext = createContext({});

const Form = ({ className, children, initials, control, formState, status, handleSubmit, setValue, setError, clearErrors, reset, onSubmit, onError, onSuccess, formSucess, formFooter, formPrivacy }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(initials || {}).forEach(fieldName => {
      setValue(fieldName, initials[fieldName]);
    })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status?.isSuccess) {
      onSuccess && onSuccess();
      formSucess && dispatch(appActions.toggleModal(true));
    } else {
      dispatch(appActions.toggleModal(false));
    }
    // eslint-disable-next-line
  }, [dispatch, status?.isSuccess]);

  useEffect(() => {
    if (status?.isError) {
      if (isDict(status?.errorData)) {
        Object.keys(status?.errorData || {}).forEach(fieldName => {
          setError(fieldName, {
            type: "server",
            message: status?.errorData[fieldName],
          });
        })
      } else {
        setError('detail', {
          type: "server",
          message: "Произошла непредвиденная ошибка. Повторите снова",
        });
      }
    }
    // eslint-disable-next-line
  }, [status?.isError, status?.errorData]);

  const context = {
    control,
    formState,
    status,
  }
  
  return (
    <form 
      className={`form ${className} ${status?.isSuccess ? 'form_success' : ''}`} 
      onSubmit={e => {
        clearErrors();
        handleSubmit(onSubmit, onError)(e)
      }}
    >
      <FormContext.Provider value={context}>
        {children}

        <FormError errorData={status?.errorData} />

        {formPrivacy && <div className="formPrivacy">{formPrivacy()}</div>}

        {formFooter && <div className="formFooter">{formFooter(status)}</div>}

        {formSucess && formSucess(status)}
      </FormContext.Provider>
    </form>
  );
}

Form.FormContext = FormContext;

export default Form;
