import SvgIcon from "components/SvgIcon"

import "./styles.css"


const ContactItem = ({ icon, title, value, url }) => {
  return (
    <div className="contactItem">
      <a className="contactItemLink" href={url} target="_blank" rel="noreferrer">
        {icon && <SvgIcon className={`contactItemLink__icon ${icon}`} icon={icon} />}
        <div>
          <div className="contactItemLink__title">{title}</div>
          <div className="contactItemLink__value">{value}</div>
        </div>
      </a>
    </div>
  );
};

export default ContactItem;
