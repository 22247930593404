import { useSelector } from 'react-redux'
import { Link } from "react-router-dom"

import Card from '../Card'
import CardBenefits from '../CardBenefits'

import './styles.css'


const CardLanding = () => {
  const cardDetail = useSelector(state => state.card.detail);
  const cardType = cardDetail?.cardType;

  return (
    <div className="cardLp">
      <Card name={cardType?.name} number="0000000000" />

      <div className="cardLp__btns">
        <Link className="btn btn_size_lg" to="/club/request/existing/">У меня есть карта</Link>
        <div style={{ margin: '0 8px' }}></div>
        <Link className="btn btn_size_lg btnBorder" to="/club/request/">Выпустить карту</Link>
      </div>

      <div className='cardLp__h1'>
        Программа лояльности<br /> «{cardType?.name}»
      </div>
      {cardType?.text &&
        <div className='cardLp__text'>
          {cardType?.text}
        </div>}

      <CardBenefits benefits={cardType?.benefits || []} />
    </div>
  );
};

export default CardLanding;
