import { api } from 'redux/api/api'


const newsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNewsPostList: builder.query({
      query: (params = null) => ({
        url: 'news/post/',
        params: params,
      }),
    }),
    getNewsPostSimpleList: builder.query({
      query: (params = null) => ({
        url: 'news/post/',
        params: params,
      }),
    }),
    getNewsPostDetail: builder.query({
      query: (slug) => ({
        url: `news/post/${slug}/`,
      }),
    }),
  }),
});

export default newsApi;

const {
  useLazyGetNewsPostListQuery,
  useLazyGetNewsPostSimpleListQuery,
  useLazyGetNewsPostDetailQuery,
} = newsApi;

export {
  useLazyGetNewsPostListQuery as useLazyGetListQuery,
  useLazyGetNewsPostSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetNewsPostDetailQuery as useLazyGetDetailQuery,
};

