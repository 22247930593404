import FieldController from "../FieldController"

import "./styles.css"


const SelectField = ({ options, ...props }) => {
  return (
    <FieldController
      {...props}
      render={({ field, className, placeholder, error }) => {
        return (
          <div className={`${className} selectField`}>
            {props.label && <label>{props.label} {props.required && <abbr title="обязательное поле">*</abbr>}</label>}
            <select
              className={!field.value ? 'emptyVal': undefined}
              {...field}
              disabled={props.disabled}
            >
              <option value="">{placeholder}</option>
              {options.map((opt, idx) => (<option key={`opt${idx}`} value={opt.value}>{opt.label}</option>))}
            </select>
            {error && <div className="field__error">{error.message}</div>}
          </div>
        )
      }}
    />
  );
};

export default SelectField;
