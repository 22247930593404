import React, { useMemo } from 'react'
import Slider from "react-slick"

import SvgIcon from 'components/SvgIcon'

import './styles.css'


const DetailHeader = ({ image, logo, name, title, properties }) => {
  const images = useMemo(() => {
    let _images = [];
    if (Array.isArray(image)) {
      _images = image;
    } else if (image) {
      _images = [image];
    }
    return _images;
  }, [image]);

  const hasImages = images.length > 0;

  let cls = "detailHeader";
  if (hasImages) {
    cls += " hasImages"
  }
  if (logo) {
    cls += " hasLogo"
  }

  return (
    <div className={cls}>
      {hasImages && <Slider className="detailHeaderSlick" dots={true} arrows={false} infinite={true} autoplay={false} speed={500} slidesToShow={1} slidesToScroll={1}>
        {images.map((img, i) => (
          <div key={`img${i}`} className="detailHeader__pic">
            <img className="detailHeader__picImg" src={img.url || img} alt="" />
          </div>
        ))}
      </Slider>}

      {title && <div className="detailHeaderBody">
        {logo && <div className="detailHeaderBody__logo">
          <img src={logo.url || logo} alt={name} />
        </div>}
        <div>
          <div className="detailHeaderBody__title">{title}</div>
          {properties && properties.map((item, index) => {
            if (!item.value) return null;
            return (
              <div key={`item${index}`} className="detailHeaderBody__property">
                {item.icon && <SvgIcon icon={item.icon} />}
                <div>{item.value}</div>
              </div>
            )
          })}
        </div>
      </div>}
    </div>
  );
};

export default DetailHeader;
