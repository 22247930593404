import { useState } from 'react'

import "./styles.css"


const Toggle = ({ btn1, btn2, onChange }) => {
  const [state, setState] = useState(1);

  const update = (index) => {
    setState(index);
    if (onChange) onChange(index);
  }

  return (
    <div className={`toggle toggle_state${state}`}>
      <div className="toggle__btn"></div>
      <div className="toggle__item" onClick={() => update(1)}>
        {btn1}
      </div>

      <div className="toggle__item" onClick={() => update(2)}>
        {btn2}
      </div>
    </div>
  );
};

export default Toggle;
