import React from 'react'

import SvgIcon from 'components/SvgIcon'
import Loader from 'components/Loader'

import './styles.css'


const Button = ({ className, icon, title, size, type, loading, onClick }) => {
  let cls = "btn";
  if (className) cls += ` ${className}`;
  if (size) cls += ` btn_size_${size}`;
  if (loading) cls += ` btn_loading`;
  return (
    <button className={cls} type={type || "button"} onClick={onClick}>
      {icon && <SvgIcon className="btn__icon" icon={icon} />}
      {title}
      {loading && <Loader isLoading={true} />}
    </button>
  );
};

export default Button;
