import { createSlice } from '@reduxjs/toolkit'

import { parseJwt } from 'utils/jwt'
import AuthApi from './api'


const initialState = {
  token: null,
  user: null,

  otpUserId: null,
  authNext: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    logout: () => initialState,

    setCredentials: (state, { payload: { token, user } }) => {
      state.token = token;
      state.user = user;
    },

    setAuthNext: (state, action) => {
      console.log('action', action)
      state.authNext = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(AuthApi.endpoints.loginUser.matchFulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = parseJwt(action.payload.token);
      })
      
      .addMatcher(AuthApi.endpoints.loginOauth.matchFulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = parseJwt(action.payload.token);
      })

      .addMatcher(AuthApi.endpoints.loginUserOTP.matchFulfilled, (state, action) => {
        if (action.payload.token) {
          state.token = action.payload.token;
          state.user = parseJwt(action.payload.token);
          state.otpUserId = null;
        } else {
          state.otpUserId = action.payload.userId;
        }
      })
      .addMatcher(AuthApi.endpoints.logoutUser.matchFulfilled, (state, action) => {
        return initialState;
      })
      .addMatcher(AuthApi.endpoints.tokenRefresh.matchFulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = parseJwt(action.payload.token);
      })
      .addMatcher(AuthApi.endpoints.tokenRefresh.matchRejected, (state, action) => {
        return initialState
      })

      .addMatcher(AuthApi.endpoints.updateUser.matchFulfilled, (state, action) => {
        state.user = action.payload;
      })
  },
});

export default authSlice.reducer;

export const { logout, setCredentials, setAuthNext } = authSlice.actions;
