import React, { useMemo } from 'react'

import { isDict } from 'utils/helpers'

import './styles.css'


const FormError = ({ className, errorData }) => {
  const errors = useMemo(() => {
    if (!errorData) return '';
    if (errorData?.non_field_errors) {
      return errorData.non_field_errors
    }
    if (errorData?.detail) {
      return errorData.detail
    }
    if (isDict(errorData)) {
      return null;
    }
    return 'Не предвиденная ошибка';
  }, [errorData]);

  let cls = 'error';
  if (className) cls += ` ${className}`;

  if (!errors) return null;

  return (
    <div className={cls} dangerouslySetInnerHTML={{ __html: errors }}/>
  );
};

export default FormError;
