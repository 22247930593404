import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

import { getFullName, phoneFormat } from "utils/helpers"
import { useUpdateMutation } from 'models/cardRequest/api'
import { Form, FormSuccess, ReadonlyFieldset, ReadonlyField, Fieldset, TextField } from "components/forms"
import Button from "components/Button"

import "./styles.css"


const CardRequestForm = ({ initials }) => {
  const { control, handleSubmit, reset, formState, setValue, setError, clearErrors } = useForm();
  const user = useSelector(state => state.auth.user);
  const [update, updateProps] = useUpdateMutation();

  const navigate = useNavigate();

  const onSubmit = data => {
    if (!updateProps.isLoading) {
      if (!data?.phone) {
        data.phone = user?.phone;
      }
      console.log(data);

      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      update(formData);
    }
  }

  const onError = (errors, e) => {
    console.log(errors, e);
  }

  const phone = user?.phone && !user?.phone.startsWith('@') ? user?.phone : '';

  return (
    <Form
      initials={initials}
      control={control}
      formState={formState}
      onSubmit={onSubmit}
      onError={onError}
      handleSubmit={handleSubmit}
      reset={reset}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
      status={{
        isLoading: updateProps.isLoading,
        isSuccess: updateProps.isSuccess,
        isError: updateProps.isError,
        errorData: updateProps.error?.data,
        successData: updateProps.data,
      }}
      formFooter={(status) => (
        <Button type="submit" title="Оставить заявку" size="lg" loading={status.isLoading} />
      )}
      formSucess={(status) => (
        status?.successData?.status === 'completed' ?
          <FormSuccess
            title="Карта успешно привязана"
            btnTitle="Перейти к карте"
            status={status}
            onClose={() => {
              reset();
              navigate(`/club/`, { replace: true });
            }}
          >
            Теперь вы можете пользоваться вашей картой.
          </FormSuccess> :
          <FormSuccess
            title="Ваша заявка принята"
            btnTitle="Закрыть"
            status={status}
            onClose={() => {
              reset();
              navigate(`/club/`, { replace: true });
            }}
          >
            В ближайшее время мы свяжемся с вами.
          </FormSuccess>
      )}
    >
      <div className="formText">
        <h2>Заявка на клубную карту</h2>
      </div>

      <ReadonlyFieldset>
        <ReadonlyField value={getFullName(user)} />
        <ReadonlyField value={phoneFormat(phone) || user?.email} />
      </ReadonlyFieldset>

      <Fieldset>
        {!phone &&
          <TextField
            name="phone"
            placeholder="Введите номер телефона"
            required={true}
          />}

      </Fieldset>
    </Form>
  );
};

export default CardRequestForm;
