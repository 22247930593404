import { Panel } from 'layout'
import TenantListItem from './TenantListItem'

import "./styles.css"


const SchemePlaceList = ({ places, onOpenPlace, levelName }) => {
  const [tenantList] = places.reduce((res, place) => {
    let tenant = null;
    if (place.tenant?.id) {
      tenant = { ...place.tenant, key: `tenant${place.tenant.id}` };
    } else if (place.hall?.id) {
      tenant = { ...place.hall, key: `hall${place.hall.id}` };
    }
    let [lst, ids] = res;
    if (tenant) {
      const idx = ids.indexOf(tenant.key);
      if (idx === -1) {
        tenant.places = [place];
        ids.push(tenant.key);
        lst.push(tenant);
      } else {
        lst[idx].places.push(place);
      }
    }
    return [lst, ids];
  }, [[], []]);

  if (tenantList.length === 0) return null;

  const levelTitle = levelName ? `, ${levelName} этаж` : '';

  return (
    <Panel title={`Компании${levelTitle}`} style={{ padding: "4px 0 0" }}>
      <div className="tenantList">
        {tenantList.map(tenant => <TenantListItem key={tenant.key} tenant={tenant} onOpenPlace={onOpenPlace} />)}
      </div>
    </Panel>
  );
};

export default SchemePlaceList;
