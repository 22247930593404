import { NavLink } from "react-router-dom"

import { dateFormat, timeFormat } from "utils/helpers"
import SvgIcon from "components/SvgIcon"

import "./styles.css"


const HallAccessListItem = ({ access }) => {
  return (
    <NavLink className="accessItem" to={`/hall/access/${access.id}/`}>
      <SvgIcon className="accessItem__icon" icon="qr" />

      <div className="accessItem__main">
        <div className="accessItem__top">
          <div className="accessItem__number">QR-ключ к залу</div>
        </div>

        <div className="accessItem__question">{access.hall.name}</div>
        <div className="accessItem__last">
          <span>{dateFormat(access.eventStart)} </span>
          <span>{timeFormat(access.eventStart)}-{timeFormat(access.eventEnd)}</span>
        </div>
      </div>
    </NavLink>
  );
};

export default HallAccessListItem;
