import React from 'react'

import { Page, Header } from 'layout'
import NewsList from 'models/news/components/NewsList'

import './styles.css'


const NewsPage = () => {
  return (
    <Page
      renderHeader={(pageRef) => <Header pageRef={pageRef} title="Новости" />}
      withHeader={true}
    >
      <NewsList />
    </Page>
  );
};

export default NewsPage;
