import { NavLink } from "react-router-dom"

import "./styles.css"


const TenantCard = ({ tenant }) => {
  const logo = tenant?.icon && tenant?.icon[0];
  return (
    <NavLink className={`tenantCard ${logo ? '': 'tenantCard_emptyLogo'}`} to={`/tenant/${tenant.slug}/`}>
      {logo && <img className="tenantCard__logo" src={logo?.url} alt={tenant.name} />}
      <div className="tenantCard__body">
        <div className="tenantCard__title">{tenant.name}</div>
      </div>
    </NavLink>
  );
};

export default TenantCard;
