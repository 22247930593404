import { NavLink } from "react-router-dom"

import SvgIcon from "components/SvgIcon"

import "./styles.css"

const STATUSES = {
  'open': {
    title: 'Новое',
    icon: 'open',
  },
  'inProgress': {
    title: 'В работе',
    icon: 'inProgress',
  },
  'completed': {
    title: 'Закрыто',
    icon: 'completed',
  }
};

const FeedbackRequestListItem = ({ request }) => {
  const status = STATUSES[request.status] || STATUSES['open'];
  return (
    <NavLink className="requestItem" to={`/feedback/history/${request.id}/`}>
      <div className="requestItem__top">
        <div className="requestItem__number">Заявка №{request.id}</div>
        <div className="requestItem__date">{request.date}</div>
      </div>

      <div className="requestItem__question">{request.question}</div>
      {request.lastMessage &&
        <div className="requestItem__last">
          <span>{request.lastMessage.date}: </span>
          <span>{request.lastMessage.text}</span>
        </div>
      }
      <div className={`requestItem__status ${request.status}`}>
        <div className="requestItem__statusIcon">
          <SvgIcon icon={status.icon} />
        </div>
        <div className="requestItem__statusTitle">{status.title}</div>
      </div>
    </NavLink>
  );
};

export default FeedbackRequestListItem;
