import { api } from 'redux/api/api'


const tenantsCategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTenantCategoryList: builder.query({
      query: (params = null) => ({
        url: 'tenants/category/',
        params: params,
      }),
    }),
  }),
});

export default tenantsCategoryApi;

const {
  useLazyGetTenantCategoryListQuery,
} = tenantsCategoryApi;

export {
  useLazyGetTenantCategoryListQuery as useLazyGetListQuery,
};
