import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/hallAccess/api'
import PassCard from 'components/PassCard'


const HallPassCard = ({ style }) => {
  const [getObjectList] = useLazyGetListQuery();

  const accesssList = useSelector(state => state.hallAccess.list);

  useEffect(() => {
    getObjectList({ actual: true });
    // eslint-disable-next-line
  }, []);

  if (accesssList.length === 0) return null;

  return (
    <>
      {accesssList.map(access => (
        <PassCard
          style={style}
          title="Ключ к залу"
          url={`/hall/access/${access.id}/`}
          info={access.hall.name}
          icon="/img/icons/pass.png"
        />
      ))}
    </>

  );
};

export default HallPassCard;
