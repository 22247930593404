import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,

  detailForEdit: null,
  detail: null,
};

export const tenantSlice = createSlice({
  initialState,
  name: 'tenantSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getTenantList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
      })
      .addMatcher(api.endpoints.getTenantDetail.matchPending, (state, action) => {
        state.detail = null;
      })
      .addMatcher(api.endpoints.getTenantDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(api.endpoints.getTenantForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getTenantForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })
      .addMatcher(api.endpoints.updateTenant.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default tenantSlice.reducer;
