import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { Form, CheckboxField } from "components/forms"
import SvgIcon from "components/SvgIcon"
import Popup from 'components/Popup'
import HallCheckboxGroupField from 'models/hall/components/HallCheckboxGroupField'

import "./styles.css"


const EventFilter = ({ initials, onChange }) => {
  const { control, watch, reset, formState, setValue, setError, clearErrors } = useForm();
  const watchAllFields = watch();

  useEffect(() => {
    onChange && onChange(watchAllFields)
  }, [watchAllFields, onChange]);

  const [showPopup, setShowPopup] = useState(false);

  const onOpen = () => {
    setShowPopup(!showPopup);
  }

  const onClose = () => {
    setShowPopup(false);
  }

  return (
    <Form
      control={control}
      formState={formState}
      setValue={setValue}
      reset={reset}
      setError={setError}
      clearErrors={clearErrors}
      initials={initials}
    >
      <div className="eventFilterTop">
        <div className="eventFilterTopStart">
          <CheckboxField name="free" label="Показать свободное время" />
        </div>

        <div className="eventFilterTopEnd">
          <div className="eventFilterToggleBtn" onClick={onOpen}>
            Выбрать залы
            <SvgIcon icon="angleRight" />
          </div>
        </div>
      </div>

      <Popup
        show={showPopup}
        onClose={onClose}
        title="Выберите залы"
      >
        <div className="popupBody">
          <HallCheckboxGroupField name="hall" />
        </div>
      </Popup>
    </Form>
  );
};

export default EventFilter;
