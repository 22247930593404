import { getFullName, phoneFormat, dateFormat, timeFormat } from "utils/helpers"
import { ReadonlyFieldset, ReadonlyField } from "components/forms"
import HallRequestStatus from '../HallRequestStatus'

import "./styles.css"


const HallRequestDetail = ({ requestDetail }) => {
  if (!requestDetail) return null;

  const client = requestDetail?.client;
  const hall = requestDetail?.hall;
  const eventStart = requestDetail?.eventStart;
  //const eventEnd = requestDetail?.eventEnd;

  return (
    <>
      <ReadonlyFieldset>
        <ReadonlyField value={getFullName(client)} />
        <ReadonlyField value={phoneFormat(client?.phone) || client?.email} />

        <ReadonlyField color="light" label="Статус" value={<HallRequestStatus status={requestDetail?.status} />} />

        <ReadonlyField color="light" label="Зал" value={hall?.name} />

        <ReadonlyField color="light" label="Название мероприятия" value={requestDetail?.eventName} />

        <ReadonlyField color="light" label="Дата проведения" value={dateFormat(eventStart)} />

        <ReadonlyField color="light" label="Время проведения" value={timeFormat(eventStart)} />

        <ReadonlyField color="light" label="Длительность проведения" value={`${requestDetail?.eventDuration} ч.`} />

        <ReadonlyField color="light" label="Комментарий" value={requestDetail?.eventComment} />
      </ReadonlyFieldset>
    </>
  );
};

export default HallRequestDetail;
