import "./styles.css"


const FeedbackMessage = ({ date, author, text, isMy }) => {
  let cls = "feedbackMessageWrap";
  if (isMy) cls += " feedbackMessageWrap_isMy";
  return (
    <div className={cls}>
      <div className="feedbackMessage">
        <div className="feedbackMessage__top">
          <div className="feedbackMessage__date">{date}</div>
          <div className="feedbackMessage__number">{author}</div>
        </div>
        <div className="feedbackMessage__msg">{text}</div>
      </div>
    </div>
  );
};

export default FeedbackMessage;
