import SvgIcon from 'components/SvgIcon'

import './styles.css'


const PlaceEntrance = ({ outerPosition, place, isSelected }) => {
  if (!isSelected) return null;

  const localPos = place.schemeEntrancePosition || [0, 0];
  const position = [outerPosition[0] + localPos[0], outerPosition[1] + localPos[1]];

  const size = 50;

  return (
    <div className="schemePlaceEntrance" style={{ width: `${size}px`, height: `${size}px`, fontSize: `${size}px`, transform: `translate(${position[0]}px, ${position[1]}px)` }}>
      <SvgIcon className={`schemePlaceEntrance__icon ${place.schemeEntranceDirection}`} icon={`arrow-${place.schemeEntranceDirection}`} />
    </div>
  )
}

export default PlaceEntrance
