import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetDetailQuery } from 'models/card/api'
import CardDetail from 'models/card/components/CardDetail'
import CardLanding from 'models/card/components/CardLanding'
import CardRequestDetail from 'models/cardRequest/components/CardRequestDetail'
import { Page, TopBar, Panel } from 'layout'

import './styles.css'


const ClubCardPage = () => {
  const cardDetail = useSelector(state => state.card.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail();
    // eslint-disable-next-line
  }, []);

  const title = cardDetail?.number ? "Клубная карта": cardDetail?.request ? "Заявка на карту" : "Клубная карта";

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={title} showBack={true} />}
      hideNav={!cardDetail?.number}
      isLoading={getObjectDetailProps.isLoading}
    >
      <Panel>
        {cardDetail?.number ?
          <CardDetail /> :
          cardDetail?.request ?
            <CardRequestDetail /> :
            <CardLanding />}
      </Panel>
    </Page>
  );
};

export default ClubCardPage;
