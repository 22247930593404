import { useEffect } from 'react'
import { NavLink, useNavigate } from "react-router-dom"
import Countdown from 'react-countdown'
import moment from 'moment'

import { Panel } from 'layout'
import Loader from 'components/Loader'
import Button from 'components/Button'
import SvgIcon from "components/SvgIcon"
import { useCreateParkingPassFreeMutation } from 'models/parkingPass/api'
import { useParkingWS } from 'models/parkingPass/useHooks'


import "./styles.css"


const ParkingFreePassPanel = () => {
  const [create, createProps] = useCreateParkingPassFreeMutation();
  const navigate = useNavigate();

  const { freePass } = useParkingWS();

  const createFreePass = () => {
    create()
  }

  useEffect(() => {
    if (createProps?.isSuccess) {
      navigate('/parking/free/');
    }
    // eslint-disable-next-line
  }, [createProps?.isSuccess]);

  const isLoading = freePass === null;

  return (
    <div className="parkingFreePassPanelWrap">
      <Panel>
        {freePass?.status === 'new' || freePass?.status === 'active' ?
          <NavLink className="parkingPassPanelLink" to="/parking/free/">
            <SvgIcon className="parkingPassPanelLink__icon" icon="qr" />
            <div className="parkingPassPanelLink__title">Бесплатный час парковки</div>
            <div className="parkingPassPanelLink__text">
              {freePass?.status === 'new' ?
                <>
                  <div>Успеть активировать до {moment(freePass?.expirationDate).format('D MMMM HH:mm')}</div>
                  <span>Осталось </span>
                  <Countdown
                    date={freePass?.expirationDate}
                    daysInHours={true}
                    renderer={({ hours, minutes, seconds, completed, formatted }) => {
                      if (completed) {
                        // Render a completed state
                        return '-';
                      } else {
                        // Render a countdown
                        const h = hours ? `${hours}ч ` : '';
                        const m = minutes ? `${minutes}м ` : '';
                        const s = seconds ? `${formatted.seconds}с ` : '';
                        return <span>{h}{m}{s}</span>;
                      }
                    }}
                  />
                </>

                :
                <span>Бесплатно до {moment(freePass?.freeDate).format('D MMMM HH:mm')}</span>
              }
            </div>
          </NavLink>
          :
          <div className={`parkingPassPanelLink ${isLoading ? 'loading' : ''}`} to="/parking/free/">
            <SvgIcon className="parkingPassPanelLink__icon" icon="qr" />
            <div className="parkingPassPanelLink__title">Бесплатный час парковки</div>
            <div className="parkingPassPanelLink__text">
              {isLoading ?
                <Loader isLoading={isLoading} />
                :
                <Button title="Получить сейчас" size="md" onClick={createFreePass} loading={createProps.isLoading} />
              }
            </div>

          </div>
        }
      </Panel>
    </div>
  );
};

export default ParkingFreePassPanel;
