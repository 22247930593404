import './styles.css'


const Loader = ({ isLoading, children, theme = 'light' }) => {
  return (
    <div className={`loaderWrap ${theme} ${isLoading ? 'active' : ''}`}>
      {children}
      <div className="loader">
        <div className="loader__inner" />
      </div>
    </div>
  );
};

export default Loader;
