import React from 'react'
import { NavLink, useNavigate } from "react-router-dom"

import AuthApi from 'models/auth/api'
import { Page, Header } from 'layout'
import SvgIcon from "components/SvgIcon"

import './styles.css'


const ProfilePage = () => {
  const navigate = useNavigate();

  const [logoutUser] = AuthApi.useLogoutUserMutation();

  const onLogout = () => {
    logoutUser();
    navigate('/');
  }

  return (
    <Page
      renderHeader={(pageRef) => <Header pageRef={pageRef} />}
      withHeader={true}
    >
      <div className="list">
        <NavLink className="litItem" to={`/profile/edit/`}>
          <div className="litItem__icon">
            <img src="/img/icons/edit.png" alt=""/>
          </div>
          <div className="litItem__body">
            <div className="litItem__name">Личные данные</div>
          </div>
          <div className="litItem__icon2">
            <svg className="icon" >
              <use xlinkHref="/img/icons.svg#angleRight"></use>
            </svg>
          </div>
        </NavLink>

        <NavLink className="litItem" to={`/profile/hallrequest/`}>
          <div className="litItem__icon">
            <img src="/img/icons/calendar.png" alt=""/>
          </div>
          <div className="litItem__body">
            <div className="litItem__name">Мои бронирования залов</div>
          </div>
          <div className="litItem__icon2">
            <svg className="icon" >
              <use xlinkHref="/img/icons.svg#angleRight"></use>
            </svg>
          </div>
        </NavLink>
      </div>

      <div className="logout" onClick={onLogout}>
        <SvgIcon icon="logout" />
        Выйти
      </div>
    </Page>
  );
};

/*
<NavLink className="litItem" to={`/profile/`}>
          <div className="litItem__icon">
            <img src="/img/icons/parking.png" alt=""/>
          </div>
          <div className="litItem__body">
            <div className="litItem__name">Мои абонементы на парковку</div>
          </div>
          <div className="litItem__icon2">
            <svg className="icon" >
              <use xlinkHref="/img/icons.svg#angleRight"></use>
            </svg>
          </div>
        </NavLink>
*/

export default ProfilePage;
