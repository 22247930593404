import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,
};

export const tenantCategorySlice = createSlice({
  initialState,
  name: 'tenantCategorySlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getTenantCategoryList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
      })
  },
});

export default tenantCategorySlice.reducer;
