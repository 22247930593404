import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/buildingLevel/api'
import Tabs from 'components/Tabs'
import Scheme from 'components/Scheme'

import './styles.css'


const BuildingSchemeSimple = ({ buildings, activePlaces }) => {
  const [currentBuildingId, setCurrentBuildingId] = useState(null);
  const [getObjectList, getObjectListProps] = useLazyGetListQuery();
  const buildingLevelList = useSelector(state => state.buildingLevel.list);


  const changeBuilding = (building) => {
    if (building?.id && building?.id !== currentBuildingId) {
      setCurrentBuildingId(building?.id || null);
      getObjectList({ building: building.slug, id: building.levels });
    }
  }

  const onTabChange = (tab) => {
    changeBuilding(tab);
  }

  return (
    <div className="buildingScheme">
      <div className="buildingScheme__tabs">
        <Tabs tabs={buildings} onTabChange={onTabChange} />
      </div>

      {currentBuildingId &&
        <Scheme
          maxHeight={300}
          simple={true}
          levels={buildingLevelList}
          isLoading={getObjectListProps.isLoading}
          activePlaces={activePlaces}
          hideNav={buildingLevelList.length < 2}
        />
      }
    </div>
  );
}

export default BuildingSchemeSimple
