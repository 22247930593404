import { useSelector } from 'react-redux'

import Card from '../Card'
import CardBenefits from '../CardBenefits'

import './styles.css'


const CardDetail = () => {
  const cardDetail = useSelector(state => state.card.detail);
  const cardType = cardDetail?.cardType;

  return (
    <>
      <Card name={cardType?.name} code={cardType?.code} number={cardDetail?.number} />

      <CardBenefits title="Доступные привилегии" benefits={cardType?.benefits || []} />
    </>
  );
};

export default CardDetail;
