import React, { useEffect, useCallback } from 'react'
import { useParams, useLocation, Link } from "react-router-dom"
import { useSelector } from 'react-redux'
import ImageGallery from 'react-image-gallery'

import { useLazyGetDetailQuery } from 'models/tenant/api'
import { Page, TopBar, Panel } from 'layout'
import Tag from 'components/Tag'
import DetailHeader from 'components/DetailHeader'
import DetailText from 'components/DetailText'
import PathInfo from 'components/PathInfo'
import PropertyList from 'components/PropertyList'
import BuildingSchemeSimple from 'models/buildingLevel/components/BuildingSchemeSimple'

import './styles.css'


const TenantDetailPage = () => {
  const { tenantSlug } = useParams();
  const location = useLocation();
  const tenantDetail = useSelector(state => state.tenant.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail(tenantSlug);
    // eslint-disable-next-line
  }, [tenantSlug]);

  const tryScrollToPath = useCallback((node) => {
    if (getObjectDetailProps.isSuccess && location.hash === "#path") {
      setTimeout(() => {
        node && node.scrollIntoView({ behavior: 'smooth' })
      }, 500);
    }
  }, [location, getObjectDetailProps.isSuccess]);

  const images = tenantDetail?.images.map(img => ({
    original: img.url || img,
    thumbnail: img.url || img,
  }));

  const localAddress = tenantDetail?.localAddress || tenantDetail?.address;
  let fullAddress = [];
  if (tenantDetail?.address) fullAddress.push(tenantDetail?.address);
  if (tenantDetail?.localAddress) fullAddress.push(tenantDetail?.localAddress);
  fullAddress = fullAddress.join(", ");

  const worktime = tenantDetail?.worktime ? tenantDetail?.worktime.split('\n').map((item, idx) => <div key={`item${idx}`}>{item}</div>) : '';
  const places = tenantDetail?.places || [];
  const activePlaces = places.map(place => place.id);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={tenantDetail?.name} showBack={true} />}
      hideNav={true}
      isLoading={!tenantDetail?.id || getObjectDetailProps.isLoading}
    >
      <DetailHeader
        image={tenantDetail?.images[0]}
        logo={tenantDetail?.icon[0]}
        name={tenantDetail?.name}
        title={tenantDetail?.name}
        properties={[
          { icon: 'pin', value: localAddress },
          { icon: 'phone', value: tenantDetail?.phone },
        ]}
      />

      <DetailText text={tenantDetail?.text} />

      {tenantDetail?.services?.length > 0 &&
        <div className="tenantDetailServices">
          <div className="tenantDetailServices__title">Услуги компании:</div>
          <div className="tenantDetailServices__tags">
            {tenantDetail?.services.map(item =>
              <Tag key={`service${item.slug}`}>
                <Link to={`/tenant/?services=${item.slug}`} replace={true}>#{item.name}</Link>
              </Tag>)}
          </div>
        </div>
      }

      {images && images.length > 1 &&
        <Panel style={{ padding: '0 0 24px' }}>
          <ImageGallery items={images} />
        </Panel>
      }

      <hr />

      <div ref={tryScrollToPath}>
        <Panel title="Как нас найти">
          {tenantDetail &&
            <PropertyList
              style={{ padding: '0', border: '0' }}
              items={[
                { icon: 'pin', value: fullAddress },
                { icon: 'phone', value: `${tenantDetail?.phone}` },
                { icon: 'worktime', value: worktime },
              ]}
            />}

          <br />
          <BuildingSchemeSimple buildings={tenantDetail?.buildings || []} activePlaces={activePlaces} />
          <br />

          <PathInfo title="Как пройти" text={tenantDetail?.pathText} />
        </Panel>
      </div>
    </Page>
  );
};

export default TenantDetailPage;
