import React from 'react'

import { useLazyGetSimpleListQuery } from 'models/hall/api'
import ListLoadComponent from 'components/ListLoadComponent'
import { CheckboxGroupField } from "components/forms"


const HallCheckboxGroupField = ({ filter, onLoaded, ...props }) => {
  const [getObjectList, getObjectListProps] = useLazyGetSimpleListQuery();

  return (
    <ListLoadComponent
      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      filter={filter}
      onLoaded={onLoaded}

      render={({ items }) => {
        const options = items.map(item => (
          { value: item.id.toString(), label: item.name }
        ));
        return (
          <CheckboxGroupField options={options} {...props} />
        );
      }}
    />
  );
}

export default HallCheckboxGroupField
