const loadStatus = (status, errors = null) => {
    return {
        status: status,
        errors: errors,
        notAsked: status === "notAsked",
        isLoading: status === "loading",
        isLoaded: status === "loaded",
        isError: status === "failed",
    };
}

loadStatus.notAsked = "notAsked";
loadStatus.loading = "loading";
loadStatus.loaded = "loaded";
loadStatus.failed = "failed";

export {
    loadStatus,
};
