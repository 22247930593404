import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { getFullName, phoneFormat } from "utils/helpers"
import { useUpdateMutation } from 'models/hallRequest/api'
import HallSelectField from 'models/hall/components/HallSelectField'
import { Form, FormSuccess, ReadonlyFieldset, ReadonlyField, Fieldset, TextField, TextareaField, DateField, SelectField } from "components/forms"
import Button from "components/Button"

import "./styles.css"


const HallRequestForm = ({ user, initials }) => {
  const { control, handleSubmit, reset, formState, setValue, setError, clearErrors } = useForm();
  const [update, updateProps] = useUpdateMutation();

  const navigate = useNavigate();

  const onSubmit = data => {
    if (!updateProps.isLoading) {
      console.log(data);
      update(data);
    }
  }

  const onError = (errors, e) => {
    console.log(errors, e);
  }

  const onHallLoaded = (items) => {

  }

  return (
    <Form
      initials={initials}
      control={control}
      formState={formState}
      onSubmit={onSubmit}
      onError={onError}
      handleSubmit={handleSubmit}
      reset={reset}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
      status={{
        isLoading: updateProps.isLoading,
        isSuccess: updateProps.isSuccess,
        isError: updateProps.isError,
        errorData: updateProps.error?.data,
        successData: updateProps.data,
      }}
      formFooter={(status) => (
        <Button type="submit" title="Оставить заявку" size="lg" loading={status.isLoading} />
      )}
      formSucess={(status) => (
        <FormSuccess
          title="Ваша заявка принята"
          btnTitle="Перейти к заявке"
          onClose={() => {
            reset();
            navigate(`/profile/hallrequest/${status.successData?.id}/`, { replace: true });
          }}
        >
          Номер вашей заявки <b className="mark">{status.successData?.id}</b>
        </FormSuccess>
      )}
    >
      <div className="formText">
        <div>Опишите вашу задачу и&nbsp;оставьте заявку на&nbsp;её исполнение</div>
        <div>* - поля, обязательные для заполнения</div>
      </div>

      <ReadonlyFieldset>
        <ReadonlyField value={getFullName(user)} />
        <ReadonlyField value={phoneFormat(user?.phone) || user?.email} />
      </ReadonlyFieldset>

      <Fieldset>
        <HallSelectField
          name="hall"
          label="Зал"
          placeholder="Выберите зал"
          required={true}
          onLoaded={onHallLoaded}
        />

        <TextField
          name="eventName"
          placeholder="Введите название мероприятия"
          required={true}
        />
      </Fieldset>

      <Fieldset>
        <DateField
          name="eventDate"
          label="Дата проведения"
          placeholder="Выберите дату проведения"
          required={true}
        />

        <SelectField
          name="eventTime"
          label="Время проведения"
          placeholder="Выберите время проведения"
          options={[
            { value: '09:00', label: '09:00' },
            { value: '10:00', label: '10:00' },
            { value: '11:00', label: '11:00' },
            { value: '12:00', label: '12:00' },
            { value: '13:00', label: '13:00' },
            { value: '14:00', label: '14:00' },
            { value: '15:00', label: '15:00' },
            { value: '16:00', label: '16:00' },
            { value: '17:00', label: '17:00' },
            { value: '18:00', label: '18:00' },
            { value: '19:00', label: '19:00' },
          ]}
          required={true}
        />

        <SelectField
          name="eventDuration"
          label="Длительность проведения"
          placeholder="Выберите длительность проведения"
          options={[
            { value: 1, label: '1 час' },
            { value: 2, label: '2 часа' },
            { value: 3, label: '3 часа' },
            { value: 4, label: '4 часа' },
            { value: 5, label: '5 часов' },
            { value: 6, label: '6 часов' },
            { value: 7, label: '7 часов' },
            { value: 8, label: '8 часов' },
          ]}
          required={true}
        />
      </Fieldset>

      <Fieldset>
        <TextareaField
          name="eventComment"
          label="Комментарий"
          placeholder=""
          required={false}
        />
      </Fieldset>
    </Form>
  );
};

export default HallRequestForm;
