import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { API_URL } from 'config'


const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/api/client/v1/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `JWT ${token}`);
    }

    //console.log('endpoint', endpoint);

    if (endpoint !== 'updateCardRequest') {
      headers.set("Accept", 'application/json');
      headers.set("Content-Type", 'application/json');
    }
    return headers;
  }
})

export const api = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
})
