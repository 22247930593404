import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate, useLocation } from "react-router-dom"
import platform from "platform"

import SvgIcon from 'components/SvgIcon'

import './styles.css'


function useAddToHomescreenPrompt() {
  const [prompt, setState] = useState(null);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setState(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  return [prompt, promptToInstall];
}


export default function AddToHomeScreen() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [visibleMode, setVisibleMode] = useState(null);

  const [cookies, setCookie] = useCookies(['pwaClosed']);

  const navigate = useNavigate();
  const location = useLocation();

  const isPWAInstalled = async () => {
    if ("getInstalledRelatedApps" in window.navigator) {
      const relatedApps = await navigator.getInstalledRelatedApps();
      let installed = false;
      relatedApps.forEach((app) => {
        //if your PWA exists in the array it is installed
        if (app.url === "https://app.territory3000.ru/manifest.json") {
          installed = true;
        }
      });
      setIsAppInstalled(installed);
    }
  };

  useEffect(() => {
    isPWAInstalled();
  }, []);


  const os = (platform?.os?.family || '').toLowerCase();
  const android = os.includes('android');
  const ios = os.includes('ios');

  const doNotShowAgain = cookies.pwaClosed;

  useEffect(() => {
    const isInstallable = android || ios;

    setTimeout(() => {
      if (isInstallable && !doNotShowAgain && !isAppInstalled)
        if (prompt) {
          setVisibleMode('auto');
        } else {
          setVisibleMode('manual');
        }
    }, 1e3);

  }, [android, ios, doNotShowAgain, prompt, isAppInstalled, setVisibleMode])

  const onAutoInstall = () => {
    promptToInstall();
    setCookie('pwaClosed', true, { maxAge: 3600 * 24 * 7 });
    setVisibleMode(null);
  }

  const onClose = () => {
    setCookie('pwaClosed', true, { maxAge: 3600 });
    setVisibleMode(null);
  }

  if (location.pathname === '/add-to-home') {
    return null
  }

  if (visibleMode === 'auto') {
    return (
      <div className="addToHomeScreen" onClick={onAutoInstall}>
        <div className="addToHomeScreen__icon" >+</div>
        <div className="addToHomeScreen__close" onClick={onClose}><SvgIcon icon="close" /></div>
        <div className="addToHomeScreen__main">Добавить на домашний экран</div>
      </div>
    )
  }

  if (visibleMode === 'manual') {
    return (
      <div className="addToHomeScreen" onClick={() => { navigate('/add-to-home'); }}>
        <div className="addToHomeScreen__icon" >+</div>
        <div className="addToHomeScreen__close" onClick={onClose}><SvgIcon icon="close" /></div>
        <div className='addToHomeScreen__main'>
          Добавить на домашний экран
        </div>
      </div>
    )
  }

  return null
};

