import PassCard from 'components/PassCard'


const ClubCardPassCard = ({ style }) => {
  return (
    <PassCard
      style={style}
      title="Клубная карта"
      url="/club/"
      info="Программа лояльности"
      icon="/img/icons/cards.png"
    />
  );
};

export default ClubCardPassCard;
