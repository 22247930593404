import { api } from 'redux/api/api'


const tenantsServiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTenantServiceList: builder.query({
      query: (params = null) => ({
        url: 'tenants/service/',
        params: params,
      }),
    }),
    getTenantServiceDetail: builder.query({
      query: (slug) => ({
        url: `tenants/service/${slug}/`,
      }),
    }),
  }),
});

export default tenantsServiceApi;

const {
  useLazyGetTenantServiceListQuery,
  useLazyGetTenantServiceDetailQuery,
} = tenantsServiceApi;

export {
  useLazyGetTenantServiceListQuery as useLazyGetListQuery,
  useLazyGetTenantServiceDetailQuery as useLazyGetDetailQuery,
};
