import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/hallRequest/api'
import HallRequestListItem from '../HallRequestListItem'

import "./styles.css"


const HallRequestList = ({ filter }) => {
  const requests = useSelector(state => state.hallRequest.list);
  
  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    getObjectList(filter);
    // eslint-disable-next-line
  }, [filter])

  return (
    <div className="hallList">
      {requests.map(request => <HallRequestListItem key={`request${request.id}`} request={request}/>)}
    </div>
  );
};

export default HallRequestList;
