import React from 'react'

import './styles.css'


const SvgIcon = ({ className, icon, style }) => {
  let cls = 'icon';
  if (className) {
    cls += ` ${className}`;
  }
  return (
    <svg className={cls} style={style}>
      <use xlinkHref={`/img/icons.svg#${icon}`}></use>
    </svg>
  );
};

export default SvgIcon;
