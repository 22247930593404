import React, { useMemo, useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"

import { Page, TopBar, FixedTopPanel, Panel } from 'layout'
import Tag from 'components/Tag'
import { useLazyGetDetailQuery } from 'models/tenantService/api'
import TenantNav from 'models/tenant/components/TenantNav'
import TenantList from 'models/tenant/components/TenantList'
import TenantSearchBox from 'models/tenant/components/TenantSearchBox'
import TenantCategorySlider from 'models/tenantCategory/components/TenantCategorySlider'

import './styles.css'


const TenantPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [getServiceDetail, getServiceDetailProps] = useLazyGetDetailQuery();

  const [currentCategorySlug, currentServiceSlug] = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return [searchParams.get('category'), searchParams.get('services')]
  }, [location.search]);


  const [serviceName, setServiceName] = useState('');

  useEffect(() => {
    setServiceName('');
    if (currentServiceSlug) {
      getServiceDetail(currentServiceSlug);
    }
    // eslint-disable-next-line
  }, [currentServiceSlug]);

  useEffect(() => {
    setServiceName(getServiceDetailProps?.data?.name);
    // eslint-disable-next-line
  }, [getServiceDetailProps?.data?.status, getServiceDetailProps?.data?.name]);

  const [mode, setMode] = useState(1);

  const onModeChange = (index) => {
    setMode(index);
  }

  const clearService = () => {
    let url = '/tenant/';
    if (currentCategorySlug) {
      url += `?category=${currentCategorySlug}`;
    }
    navigate(url, { replace: true });
  }

  const serviceTitle = serviceName && <div>Компании с услугой <Tag onDelete={clearService}>#{serviceName}</Tag></div>;

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="Арендаторы" showBack={true} />}
      hideNav={true}
    >
      <FixedTopPanel>
        <TenantSearchBox placeholder="Поиск по арендаторам" searchText={serviceName} />

        <TenantCategorySlider />

        <TenantNav onModeChange={onModeChange} />
      </FixedTopPanel>

      <Panel style={{ padding: "4px 0 0" }} title={serviceTitle}>
        <TenantList mode={mode} categorySlug={currentCategorySlug} serviceSlug={currentServiceSlug} />
      </Panel>
    </Page>
  );
};

export default TenantPage;
