import { useEffect } from 'react'

import { Page, Header } from 'layout'
import { useLazyGetParkingPassFreeQuery } from 'models/parkingPass/api'
import ParkingFreePassPanel from 'models/parkingPass/components/ParkingFreePassPanel'

import './styles.css'


const ParkingPage = () => {
  const [getFreePass, getFreePassProps] = useLazyGetParkingPassFreeQuery();

  useEffect(() => {
    getFreePass();
    // eslint-disable-next-line
  }, []);

  return (
    <Page
      renderHeader={(pageRef) => <Header pageRef={pageRef} title="Парковка" />}
      withHeader={true}
      isLoading={getFreePassProps.isLoading}
    >
      <ParkingFreePassPanel />
    </Page>
  );
};

export default ParkingPage;
