import React from 'react'

import './styles.css'


const PathInfo = ({ title, text, style }) => {
  if (!text) return null;
  return (
    <div className="pathInfo" style={style}>
      {title && <div className="pathInfo__title">{title}</div>}
      <div className="pathInfo__text" dangerouslySetInnerHTML={{ __html: text }}/>
    </div>
    
  );
};

export default PathInfo;
