import { NavLink } from 'react-router-dom'

import SvgIcon from "components/SvgIcon"
import Toggle from "components/Toggle"


import "./styles.css"


const TenantNav = ({ onModeChange }) => {
  return (
    <div className="tenantNav">
      <div className="tenantNav__start">
        <Toggle
          btn1={<SvgIcon icon="grid" />}
          btn2={<SvgIcon icon="list" />}
          onChange={onModeChange}
        />
      </div>

      <div className="tenantNav__end">
        <NavLink className="tenantNavLink tenantMapLink" to="/scheme/">
          <SvgIcon icon="plan" />
          Схема корпусов
        </NavLink>
      </div>
    </div>
  );
};

export default TenantNav;
