import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
import Countdown from 'react-countdown'
import moment from 'moment'

import './styles.css'


const QRCodePass = ({ code, title, name, pass, startTitle = 'Время въезда', endTitle = 'Срок действия' }) => {
  return (
    <div className="codePassWrap">
      <div className="codePassCard">
        <div className="codePassCard__title">{title}</div>
        <div className="codePassCard__name">{name}</div>

        <div className="codePassCard__grid">
          <div className="codePassCard__gridItem">
            <div className="codePassCard__gridItemLbl">{startTitle}</div>
            <div className="codePassCard__gridItemVal">
              {pass?.status === 'new' ?
                '--//--'
                :
                moment(pass?.entryDate).format('D MMMM HH:mm')
              }
            </div>
          </div>
          {pass?.status === 'new' ?
            <div className="codePassCard__gridItem">
              <div className="codePassCard__gridItemLbl">Срок активации</div>
              <div className="codePassCard__gridItemVal">{moment(pass?.expirationDate).format('D MMMM HH:mm')}</div>
              <div className="codePassCard__gridItemVal">
                <span>Осталось </span>
                <Countdown
                  date={pass?.expirationDate}
                  daysInHours={true}
                  renderer={({ hours, minutes, seconds, completed, formatted }) => {
                    if (completed) {
                      // Render a completed state
                      return '-';
                    } else {
                      // Render a countdown
                      const h = hours ? `${hours}ч ` : '';
                      const m = minutes ? `${minutes}м ` : '';
                      const s = seconds ? `${formatted.seconds}с ` : '';
                      return <span>{h}{m}{s}</span>;
                    }
                  }}
                />
              </div>
            </div>
            :
            <div className="codePassCard__gridItem">
              <div className="codePassCard__gridItemLbl">{endTitle}</div>
              <div className="codePassCard__gridItemVal">{moment(pass?.freeDate).format('D MMMM HH:mm')}</div>
            </div>
          }
        </div>

        <div className="codePassCard__qrTitle">Отсканируйте QR-код</div>
        <div className="codePassCard__qr">
          <QRCodeSVG value={code} />
        </div>
      </div>
    </div>
  );
};

export default QRCodePass;
