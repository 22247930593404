import { api } from 'redux/api/api'


const HallApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHallList: builder.query({
      query: (params = null) => ({
        url: 'hall/hall/',
        params: params,
      }),
    }),
    getHallSimpleList: builder.query({
      query: (params = null) => ({
        url: 'hall/hall/',
        params: params,
      }),
    }),
    getHallDetail: builder.query({
      query: (id) => ({
        url: `hall/hall/${id}/`,
      }),
    }),
  }),
});

export default HallApi;

const {
  useLazyGetHallListQuery,
  useLazyGetHallSimpleListQuery,
  useLazyGetHallDetailQuery,
} = HallApi;

export {
  useLazyGetHallListQuery as useLazyGetListQuery,
  useLazyGetHallSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetHallDetailQuery as useLazyGetDetailQuery,
};

