import { useSelector } from 'react-redux'

import { getFullName, dateFormat } from "utils/helpers"
import { ReadonlyFieldset, ReadonlyField } from "components/forms"
import Card from 'models/card/components/Card'
import CardRequestStatus from '../CardRequestStatus'

import "./styles.css"


const CardRequestDetail = () => {
  const cardDetail = useSelector(state => state.card.detail);
  const cardType = cardDetail?.cardType;
  const requestDetail = cardDetail?.request;

  const user = useSelector(state => state.auth.user);

  if (!requestDetail) return null;

  const phone = requestDetail?.phone || '';

  return (
    <>
      <Card name={cardType?.name} number="0000000000" />

      <ReadonlyFieldset>
        <ReadonlyField value={getFullName(user)} />
        <ReadonlyField value={phone} />

        <ReadonlyField color="light" label="Статус" value={<CardRequestStatus status={requestDetail?.status} />} />

        <ReadonlyField color="light" label="Дата заявки" value={dateFormat(requestDetail?.dateCreated)} />

        {requestDetail?.image &&
          <ReadonlyField
            color="light"
            label="Фотография карты"
            value={<img style={{ maxWidth: '100%' }} src={requestDetail?.image} alt="Фотография карты" />}
          />}
      </ReadonlyFieldset>
    </>
  );
};

export default CardRequestDetail;
