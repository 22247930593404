import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from "react-router-dom"

import Loader from 'components/Loader'
import { searchActions } from 'models/search/searchSlice'

import "./styles.css"


const SearchResultItem = ({ item, closeSearchPage }) => {
  const icon = item.icon && item.icon[0];
  const image = item.image && item.image[0];
  return (
    <Link className="searchPageResultItem" to={item.url} onClick={closeSearchPage}>
      {icon && <div className="searchPageResultItem__icon"><img src={icon.url} alt={item.name} /></div>}
      {image && <div className="searchPageResultItem__image"><img src={image.url} alt={item.name} /></div>}
      <div className="searchPageResultItem__name">{item.name}</div>
    </Link>
  )
}

const SearchResult = ({ title, items, closeSearchPage }) => {
  if (!items || items.length === 0) return null;
  return (
    <div className="searchPageResult">
      <div className="searchPageResult__header">
        <div className="searchPageResultTitle">{title}</div>
      </div>
      <div className="searchPageResultItems">
        {items.map(item =>
          <SearchResultItem key={`item${item.id}`} item={item} closeSearchPage={closeSearchPage} />
        )}
      </div>
    </div>
  )
}


const SearchPage = () => {
  const dispatch = useDispatch();
  const showSearchPage = useSelector(state => state.search.showSearchPage);
  const results = useSelector(state => state.search.results);
  const isLoading = useSelector(state => state.search.isLoading);

  const news = useMemo(() => {
    if (!results?.news) return [];
    return results?.news.map(post => ({
      id: post.id,
      name: post.title,
      image: post.images,
      url: `/news/${post.slug}/`
    }))
  }, [results?.news]);

  const tenantServices = useMemo(() => {
    if (!results?.tenantServices) return [];
    return results?.tenantServices.map(service => ({
      id: service.id,
      name: `#${service.name}`,
      url: `/tenant/?services=${service.slug}`
    }))
  }, [results?.tenantServices]);

  const tenants = useMemo(() => {
    if (!results?.tenants) return [];
    return results?.tenants.map(tenant => ({
      id: tenant.id,
      name: tenant.name,
      icon: tenant.icon,
      url: `/tenant/${tenant.slug}/`
    }))
  }, [results?.tenants]);

  const halls = useMemo(() => {
    if (!results?.halls) return [];
    return results?.halls.map(hall => ({
      id: hall.id,
      name: hall.name,
      icon: hall.icon,
      url: `/hall/${hall.slug}/`
    }))
  }, [results?.halls]);

  const events = useMemo(() => {
    if (!results?.events) return [];
    return results?.events.map(event => ({
      id: event.id,
      name: event.name,
      icon: event.icon,
      url: `/events/${event.id}/`
    }))
  }, [results?.events]);

  const closeSearchPage = () => {
    dispatch(searchActions.showSearchPage(false))
  }

  const location = useLocation();
  let searchResults = [["Новости", news], ["Услуги", tenantServices], ["Арендаторы", tenants], ["Залы", halls], ["События", events]];
  if (location.pathname.startsWith('/events/')) {
    searchResults = [["События", events], ["Залы", halls], ["Новости", news], ["Арендаторы", tenants]];
  }
  if (location.pathname.startsWith('/hall/')) {
    searchResults = [["Залы", halls], ["События", events], ["Новости", news], ["Арендаторы", tenants]];
  }

  const isEmpty = news.length === 0 && tenants.length === 0 && halls.length === 0 && events.length === 0;

  return (
    <div className={`searchPage ${showSearchPage ? 'active' : ''}`}>
      {isLoading ?
        <div className="searchPageLoader"><Loader isLoading={true} /></div>
        :
        <div className="searchPageResults">
          {searchResults.map(([title, items], index) => (
            <SearchResult key={`res${index}`} title={title} items={items} closeSearchPage={closeSearchPage} />
          ))}

          {isEmpty && results !== null && <div  className="searchPageResultsEmpty">Запрос не найден</div>}
        </div>
      }
    </div>
  );
}

export default SearchPage;
