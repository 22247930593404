import { useState } from "react"
import FieldController from "../FieldController"

import "./styles.css"


const CheckboxGroupField = ({ options, ...props }) => {
  const [value, setValue] = useState([]);

  return (
    <FieldController
      {...props}
      render={({ field, className, placeholder, error }) => {
        return (
          <div className={`${props.className} checkboxGroupField`}>
            {props.label && <label>{props.label} {props.required && <abbr title="обязательное поле">*</abbr>}</label>}
            {options.map((option, index) => (
              <div key={`${field.name}${index}`} className="checkboxField">
                <label>
                  <input 
                    type="checkbox" 
                    value={option.value} 
                    checked={field.value && field.value.includes(option.value)}
                    disabled={props.disabled} 
                    onChange={(e) => {
                      const valueCopy = [...value];
                      valueCopy[index] = e.target.checked ? e.target.value : null;
                      field.onChange(valueCopy);
                      setValue(valueCopy);
                    }}
                  />
                  <span>{option.label}</span>
                </label>
                {error && <div className="field__error">{error.message}</div>}
              </div>
            ))}
            {error && <div className="field__error">{error.message}</div>}
          </div>
        )
      }}
    />
  );
};

export default CheckboxGroupField;
