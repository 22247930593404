import { useEffect, useMemo } from 'react'
import { useParams, Link } from "react-router-dom"
import { useSelector } from 'react-redux'

import { useLazyGetDetailQuery } from 'models/hall/api'
import { Page, TopBar, FixedBottomPanel, Panel } from 'layout'
import DetailHeader from 'components/DetailHeader'
import DetailText from 'components/DetailText'
import PropertyList from 'components/PropertyList'
import BuildingSchemeSimple from 'models/buildingLevel/components/BuildingSchemeSimple'

import './styles.css'


const HallDetailPage = () => {
  let { hallSlug } = useParams();
  const hallDetail = useSelector(state => state.hall.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail(hallSlug);
    // eslint-disable-next-line
  }, [hallSlug]);

  const [buildings, activePlaces] = useMemo(() => {
    const place = hallDetail?.place;
    if (place) {
      const activePlaces = [place.id];
      const bName = `${place.level.building.name}, этаж ${place.level.name}`
      const buildings = [{ ...place.level.building, name: bName, levels: [place.level.id] }];
      return [buildings, activePlaces]
    }
    return [[], []]
  }, [hallDetail?.place])

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={hallDetail?.name} showBack={true} />}
      hideNav={true}
      isLoading={getObjectDetailProps.isLoading}
    >
      <DetailHeader
        image={hallDetail?.images}
        logo={hallDetail?.icon[0]}
        name={hallDetail?.name}
        title={hallDetail?.name}
      />

      <PropertyList
        items={[
          { label: 'Вместимость:', value: `${hallDetail?.capacity} человек`, icon: 'people' },
          { label: 'Оборудование:', value: `${hallDetail?.equipment}`, icon: 'presentaion' },
        ]}
      />

      <DetailText text={hallDetail?.text} />

      <Panel title="Расположение зала" style={{padding: "16px 0 120px"}}>
        <BuildingSchemeSimple buildings={buildings} activePlaces={activePlaces} />
      </Panel>

      <FixedBottomPanel>
        {hallDetail?.id && <Link className="btn btn_size_lg" to={`/hall/order/?hall=${hallDetail?.id}`}>Забронировать зал</Link>}
      </FixedBottomPanel>
    </Page>
  );
};

export default HallDetailPage;
