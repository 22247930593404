import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SvgIcon from "components/SvgIcon"

import './styles.css'


const UserTop = () => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);

  const navigate = useNavigate();

  const onClick = () => {
    navigate('/profile/');
  }

  const photoUrl = user?.photo && user?.photo[0]?.url;

  return (
    <div className="userTop" onClick={onClick}>
      <SvgIcon icon={token ? "user" : "login"} />
      {token && photoUrl && <div className="userTopAvatar" style={{ backgroundImage: `url(${photoUrl})` }} />}
    </div>
  );
};

export default UserTop;
