import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom"
import moment from 'moment'

import { Page, Header, FixedBottomPanel } from 'layout'
import EventWeekDates from 'models/event/components/EventWeekDates'
import EventFilter from 'models/event/components/EventFilter'
import EventList from 'models/event/components/EventList'


const EventPage = () => {
  let { date } = useParams();
  const navigate = useNavigate();

  const mDate = moment(date, "DD-MM-YYYY", true);

  useEffect(() => {
    if (!mDate.isValid()) {
      navigate(`/events/date/${moment().format('DD-MM-YYYY')}/`)
    }
    // eslint-disable-next-line
  }, [mDate]);

  const [showFree, setShowFree] = useState(null);
  const [hall, setHall] = useState(null);

  const onFilterChange = ({ free, hall }) => {
    setShowFree(free);
    setHall(hall);
  }

  return (
    <Page
      renderHeader={(pageRef) => <Header pageRef={pageRef} title="События" />}
      withHeader={true}
    >
      {mDate.isValid() &&
        <>
          <EventWeekDates date={mDate} today={moment()} />
          <EventFilter onChange={onFilterChange} />

          <EventList showFree={showFree} date={mDate} hall={hall} />

          <FixedBottomPanel withNav={true}>
            <Link className="btn btn_size_md" to={`/hall/order/?eventDate=${mDate.format('YYYY-MM-DD')}`}>
              Провести своё событие
            </Link>
          </FixedBottomPanel>
        </>
      }
    </Page>
  );
};

export default EventPage;
