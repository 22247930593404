import { useLocation, useNavigate } from "react-router-dom"

import Loader from 'components/Loader'
import SchemeLevelNav from './SchemeLevelNav'
import SchemeLevelPlan from './SchemeLevelPlan'
import SchemePlaceList from './SchemePlaceList'

import './styles.css'


const Scheme = ({ title, hideNav, maxHeight, levels, isLevelsLoading, activePlaces, selectedPlace, onOpenPlace, simple }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const currentLevel = parseInt(searchParams.get('level')) || 0
  const levelDetail = levels[currentLevel];
  const places = levelDetail?.places || [];

  const onLevelChange = (index) => {
    if (levels[index]) {
      navigate(`?level=${index}`, { replace: true });
    }
  };

  const height = maxHeight ? maxHeight : window.innerHeight - 60;

  const isLoading = isLevelsLoading;

  return (
    <div className="scheme">
      {!hideNav && levels.length > 0 && <SchemeLevelNav levels={levels} currentLevel={currentLevel} onLevelChange={onLevelChange} />}

      {!isLoading && levelDetail &&
        <>
          <div className="scheme__title">{title}</div>
          <SchemeLevelPlan
            height={simple ? height : height - 160}
            level={levelDetail}
            activePlaces={activePlaces}
            selectedPlace={selectedPlace}
            onOpenPlace={onOpenPlace}
            simple={simple}
          />
        </>
      }

      {!simple && <SchemePlaceList levelName={levelDetail?.name} places={places} onOpenPlace={onOpenPlace} />}

      {isLoading && <div className="pageLoader"><Loader isLoading={true} /></div>}
    </div>
  );
}

export default Scheme
