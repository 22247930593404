import { useRef, useEffect, useState } from 'react'
import { useGesture } from '@use-gesture/react'
import { useSpring, animated } from '@react-spring/web'

import SchemePlace from '../SchemePlace'

import './styles.css'


function usePreventScroll(preventScrollRef) {
  useEffect(() => {
    const preventScrolling = e => {
      if (preventScrollRef.current) {
        e.preventDefault()
      }
    }

    document.addEventListener('touchmove', preventScrolling, {
      passive: false,
    })
    return () => document.removeEventListener('touchmove', preventScrolling)
    // eslint-disable-next-line
  }, [])
}


const SchemeLevelPlan = ({ height, level, activePlaces, selectedPlace, onOpenPlace, simple }) => {
  useEffect(() => {
    const handler = (e) => e.preventDefault()
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, []);

  const ref = useRef(null);
  const [minScale, _setMinScale] = useState(1);
  const minScaleRef = useRef(minScale);
  const setMinScale = data => {
    minScaleRef.current = data;
    _setMinScale(data);
  };

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateZ: 0,
  }));

  const resize = (force = false) => {
    let winWidth = simple ? window.innerWidth - 64 : window.innerWidth - 32;
    if (winWidth > 900) winWidth = 900;
    const winHeight = height - 110;

    const wRatio = Math.round(winWidth / level.width * 1000) / 1000;
    const hRatio = Math.round(winHeight / level.height * 1000) / 1000;
    const minRatio = Math.min(wRatio, hRatio);

    if (force || minRatio !== minScaleRef.current) {
      setMinScale(minRatio);
      const x = Math.round((wRatio * level.width - level.width) / 2);
      api.start({ scale: minRatio, x })
    }
  }

  useEffect(() => {
    resize();

    const windowResize = (e) => {
      resize();
    }

    window.addEventListener('resize', windowResize);
    return () => window.removeEventListener('resize', windowResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resize(true);
    // eslint-disable-next-line
  }, [level.id]);

  const preventScrollRef = useRef(false)
  usePreventScroll(preventScrollRef)
  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], down, ...rest }) => {
        if (down && !pinching) {
          preventScrollRef.current = true
        } else {
          preventScrollRef.current = false;
        }
        if (pinching) return cancel()
        api.start({ x, y })
      },
      onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
        if (first) {
          const { width, height, x, y } = ref.current?.getBoundingClientRect();
          const tx = ox - (x + width / 2);
          const ty = oy - (y + height / 2);
          memo = [style.x.get(), style.y.get(), tx, ty];
        }

        const x = memo[0] - (ms - 1) * memo[2];
        const y = memo[1] - (ms - 1) * memo[3];

        api.start({ scale: s, rotateZ: 0, x, y });
        return memo
      },
    },
    {
      target: ref,
      drag: { from: () => [style.x.get(), style.y.get()] },
      pinch: {
        from: () => [style.scale.get(), 0],
        scaleBounds: { min: minScale, max: 2 },
        rubberband: false,
      },
    }
  );

  let cls = "levelPlan";
  if (selectedPlace) cls += " selected";

  return (
    <>
      <div className={cls} style={{ height: `${height}px` }}>
        <animated.div ref={ref} className="levelPlanScheme" style={style}>
          <div className="levelPlanScheme__inner" style={{ width: `${level.width}px`, height: `${level.height}px` }}>
            <img src={level.scheme[0].url} alt="" />
          </div>

          <div className="levelPlanDetail" style={{ width: `${level.width}px` }}>
            {level.places.map(place => {
              let isCurrent = false;
              if (activePlaces && activePlaces.length > 0) {
                isCurrent = activePlaces.includes(place.id);
                if (!place.hall && !place.tenant) {
                  return null;
                }
              }
              return (
                <SchemePlace
                  key={`place${place.id}`}
                  parentRef={ref}
                  isCurrent={isCurrent}
                  place={place}
                  selectedPlace={selectedPlace}
                  onOpenPlace={onOpenPlace}
                />
              )
            })}
          </div>
        </animated.div>
      </div>
    </>
  );
}

export default SchemeLevelPlan
