import { useState, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useSelector } from 'react-redux'

import { WS_URL } from 'config'


export function useParkingWS() {
  const user = useSelector(state => state.auth.user);

  const [freePass, setFreePass] = useState(null);

  const { readyState, sendMessage } = useWebSocket(`${WS_URL}parking/`, {
    onMessage: (event) => {
      const msg = JSON.parse(event.data);
      console.log('msg', msg);
      if (msg?.type === 'send_free_pass') {
        const free_pass = msg.free_pass || {status: 'expired'};
        setFreePass(free_pass);
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const message = {
        type: 'subscribe_free_pass',
        user_id: user?.id,
      }
      sendMessage(JSON.stringify(message));
    }
  }, [readyState, sendMessage, user?.id]);

  return { freePass };
}
