import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

import { getFullName } from "utils/helpers"
import { useUpdateMutation } from 'models/cardRequest/api'
import { Form, FormSuccess, Fieldset, TextField, ImageField } from "components/forms"
import Button from "components/Button"

import "./styles.css"


const CardRequestExistingForm = ({ initials }) => {
  const { control, handleSubmit, reset, formState, setValue, setError, clearErrors } = useForm();
  const user = useSelector(state => state.auth.user);
  const [update, updateProps] = useUpdateMutation();

  const navigate = useNavigate();

  const onSubmit = data => {
    if (!updateProps.isLoading) {
      if (!data?.phone) {
        data.phone = user?.phone;
      }
      console.log(data);

      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      update(formData);
    }
  }

  const onError = (errors, e) => {
    console.log(errors, e);
  }

  const phone = user?.phone && !user?.phone.startsWith('@') ? user?.phone : '';
  const name = getFullName(user);

  return (
    <Form
      initials={{ ...initials, phone, name }}
      control={control}
      formState={formState}
      onSubmit={onSubmit}
      onError={onError}
      handleSubmit={handleSubmit}
      reset={reset}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
      status={{
        isLoading: updateProps.isLoading,
        isSuccess: updateProps.isSuccess,
        isError: updateProps.isError,
        errorData: updateProps.error?.data,
        successData: updateProps.data,
      }}
      formFooter={(status) => (
        <div>
          <Button type="submit" title="Оставить заявку" size="lg" loading={status.isLoading} />
        </div>
      )}
      formPrivacy={() => (
        <div>Мы проверим, действительно в данных карты указаны владельцем вы и она станет активной в вашем приложении.</div>
      )}
      formSucess={(status) => (
        status?.successData?.status === 'completed' ?
          <FormSuccess
            title="Карта успешно привязана"
            btnTitle="Перейти к карте"
            status={status}
            onClose={() => {
              reset();
              navigate(`/club/`, { replace: true });
            }}
          >
            Теперь вы можете пользоваться вашей картой.
          </FormSuccess> :
          <FormSuccess
            title="Ваша заявка принята"
            btnTitle="Закрыть"
            status={status}
            onClose={() => {
              reset();
              navigate(`/club/`, { replace: true });
            }}
          >
            В ближайшее время мы свяжемся с вами.
          </FormSuccess>
      )}
    >
      <div className="formText">
        <h2>Заявка на клубную карту</h2>
      </div>

      <Fieldset>
        <TextField
          name="name"
          label="Ваше имя"
          placeholder="Введите ваше имя и фамилию"
          required={true}
          disabled={!!name}
        />

        <TextField
          name="phone"
          label="Номер вашего телефона:"
          placeholder="Введите номер телефона"
          help="Номер телефона, который вы указывали в анкете при получении карты."
          required={true}
          disabled={!!phone}
        />

        <TextField
          name="number"
          label="Номер вашей карты «Клуб 3000»:"
          placeholder="Введите номер вашей карты «Клуб 3000»"
          help="Номер карты указан на лицевой стороны карты. Номер нужно ввести полностью (10 цифр)."
          required={true}
          minLength={10}
          maxLength={10}
        />

        {updateProps.error?.data &&
          <ImageField
            name="image"
            label="Фотография карты:"
            help={<span style={{ color: '#ff0000' }}>Чтобы подтвердить, что карта действительно у вас на руках, пожалуйста, сфотографируйте ее лицевой стороной.</span>}
            required={true}
          />}
      </Fieldset>
    </Form >
  );
};

export default CardRequestExistingForm;
