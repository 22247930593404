import { useState } from "react"
import FieldController from "../FieldController"
import DatePicker from './DatePicker'

import "./styles.css"


const DateField = (props) => {
  const [showPicker, setShowPicker] = useState(false);

  return (
    <FieldController
      {...props}
      render={({ field, className, placeholder, error }) => {
        let displayValue = field.value || '';
        if (displayValue) {
          const parts = displayValue.split('-');
          displayValue = `${parts[2]}.${parts[1]}.${parts[0]}`;
        }

        return (
          <div className={`${className} dateField`}>
            {props.label && <label>{props.label} {props.required && <abbr title="обязательное поле">*</abbr>}</label>}

            <input
              onMouseDown={() => setShowPicker(true)}
              type="datetime"
              readOnly={true}
              placeholder={placeholder}
              {...field}
              value={displayValue}
              disabled={props.disabled}
            />

            <DatePicker
              value={field.value}
              show={showPicker}
              onChange={(newValue) => {
                field.onChange(newValue);
                setShowPicker(false);
              }}
              onClose={() => setShowPicker(false)}
            />

            {error && <div className="field__error">{error.message}</div>}
          </div>
        )
      }}
    />
  );
};

export default DateField;
