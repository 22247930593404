import "./styles.css"


const Fieldset = ({ children, title, titleSize }) => {
  let cls = "fieldset";

  let clsTitle = "fieldsetTitle";
  if (titleSize) clsTitle += " fieldsetTitle_size_" + titleSize;

  return (
    <div className={cls}>
      {title && <div className={clsTitle}>{title}</div>}
      {children}
    </div>
  );
};

export default Fieldset;
