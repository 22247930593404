import { api } from 'redux/api/api'


const buildingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuildingList: builder.query({
      query: (params = null) => {
        return {
          url: 'building/building/',
          params: params,
        }
      }
    }),
    getBuildingDetail: builder.query({
      query: (id) => ({
        url: `building/building/${id}/`,
      }),
    }),
  }),
});

export default buildingApi;

const {
  useLazyGetBuildingListQuery,
  useLazyGetBuildingDetailQuery,
} = buildingApi;

export {
  useLazyGetBuildingListQuery as useLazyGetListQuery,
  useLazyGetBuildingDetailQuery as useLazyGetDetailQuery,
};
