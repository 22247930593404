import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/hallAccess/api'
import HallAccessListItem from '../HallAccessListItem'

import "./styles.css"


const HallAccessList = ({ filter }) => {
  const accesss = useSelector(state => state.hallAccess.list);
  
  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    getObjectList(filter);
    // eslint-disable-next-line
  }, [filter])

  return (
    <div className="hallList">
      {accesss.map(access => <HallAccessListItem key={`access${access.id}`} access={access}/>)}
    </div>
  );
};

export default HallAccessList;
