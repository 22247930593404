import { useEffect, useState } from 'react'

import SvgIcon from 'components/SvgIcon'
import Loader from 'components/Loader'

import './styles.css'


const TopBarSelect = ({ items, currentItem, renderMain, renderItem, isLoading }) => {
  const [showDropDown, setShowDropdown] = useState(false);

  useEffect(() => {
    setShowDropdown(false);
  }, [currentItem])

  const toggleDropdown = () => {
    setShowDropdown(!showDropDown);
  }

  let cls = "topBarSelect";
  if (showDropDown) {
    cls += " active";
  }

  return (
    <div className={cls}>
      <div className="topBarSelect__main" onClick={toggleDropdown}>
        {isLoading || !currentItem ?
          <Loader />
          :
          <>
            {renderMain(currentItem)}
            <SvgIcon icon="angleRight" />
          </>
        }
      </div>
      <div className="topBarSelect__dropdown">
        {items.map(item => (renderItem(item)))}
      </div>
    </div>
  );
}

export default TopBarSelect
