import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from "react-router-dom"

import { Page, Header, Panel } from 'layout'
import NewsSlider from 'models/news/components/NewsSlider'
import FeedbackPanel from 'models/feedback/components/FeedbackPanel'
import ClubCardPassCard from 'models/card/components/ClubCardPassCard'
import ParkingPassCard from 'models/parkingPass/components/ParkingPassCard'
import HallPassCard from 'models/hallAccess/components/HallPassCard'

import './styles.css'


const HomePage = () => {
  const user = useSelector(state => state.auth.user);

  const title = useMemo(() => {
    let _title = 'ТЕРРИТОРИЯ';
    const userName = user?.first_name || user?.email;
    if (userName) {
      _title = `ТЕРРИТОРИЯ: ${userName}`;
    }
    return _title
  }, [user?.first_name, user?.email]);

  return (
    <Page
      renderHeader={(pageRef) => <Header pageRef={pageRef} title={title} showBottom={true} />}
      withHeader={true}
    >
      <Panel shadowBottom={false} style={{ padding: "24px 0 0" }}>
        <div className="myPassGrid">
          <NavLink className="myPassLink" to="/tenant/">
            <div className="myPassLink__title">Арендаторы</div>
            <img className="myPassLink__icon" src="/img/icons/flag_red.png" alt="" />
          </NavLink>

          <NavLink className="myPassLink" to="/scheme/">
            <div className="myPassLink__title">Схема корпусов</div>
            <img className="myPassLink__icon" style={{ height: "22px" }} src="/img/icons/plan_red.png" alt="" />
          </NavLink>
        </div>
      </Panel>

      <Panel title="Мои карты" shadowBottom={true}>
        <div className="passCardGrid">
          <ClubCardPassCard />

          <ParkingPassCard hide={true} />

          <HallPassCard />
        </div>
      </Panel>

      <NewsSlider />

      <FeedbackPanel />
    </Page>
  );
};

export default HomePage;
