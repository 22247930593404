import React from 'react'

import './styles.css'


const Coupon = ({ title, logo, text, discounts }) => {
  return (
    <div className="coupon">
      <div className="coupon__main">
        <div className="couponDiscount">
          {discounts.map((item, idx) => (
            <div key={`item${idx}`} className="couponDiscount__item">
              <div className="couponDiscount__itemVal">{item.val}</div>
              <div className="couponDiscount__itemLbl">{item.lbl}</div>
            </div>
          ))}
        </div>
        <div className="coupon__text">{text}</div>
      </div>

      <div className="coupon__aside">
        {logo && <div className="coupon__logo">
          <img src={logo} alt={title} />
        </div>}
        <div className="coupon__title">{title}</div>
      </div>
    </div>
  );
};

export default Coupon;
