import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,

  detail: null,
};

export const buildingLevelSlice = createSlice({
  initialState,
  name: 'buildingLevelSlice',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getBuildingLevelList.matchPending, (state, action) => {
        state.list = [];
        state.allCount = null;
      })
      .addMatcher(api.endpoints.getBuildingLevelList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
      })
      .addMatcher(api.endpoints.getBuildingLevelDetail.matchPending, (state, action) => {
        state.detail = null;
      })
      .addMatcher(api.endpoints.getBuildingLevelDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
  },
});

export default buildingLevelSlice.reducer;
