import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import platform from "platform"

import { Page, TopBar } from 'layout'
import SvgIcon from 'components/SvgIcon'

import './styles.css'


const IOSSafariIntructions = () => (
  <div className="instruction">
    <div className="instructionStep">
      <div className="instructionStep__text">
        1. Нажмите на иконку <b>«Поделиться»</b> <SvgIcon icon="share" />
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_safari1.jpg" alt="step1" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        2. В открывшемся окне нажмите <b>«На экран «Домой»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_safari2.jpg" alt="step2" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        3. В открывшемся окне нажмите <b>«Добавить»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_safari3.jpg" alt="step3" /></div>
    </div>
  </div>
)


const IOSChromeIntructions = () => (
  <div className="instruction">
    <div className="instructionStep">
      <div className="instructionStep__text">
        1. Нажмите на иконку <SvgIcon style={{transform: 'rotate(90deg)'}} icon="dots" />
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_chrome1.jpg" alt="step1" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        2. В открывшемся окне нажмите <b>«На экран «Домой»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_chrome2.jpg" alt="step2" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        3. В открывшемся окне нажмите <b>«Добавить»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_chrome3.jpg" alt="step3" /></div>
    </div>
  </div>
)


const IOSYandexIntructions = () => (
  <div className="instruction">
    <div className="instructionStep">
      <div className="instructionStep__text">
        1. Нажмите на иконку <SvgIcon icon="dots" />
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_yandex1.jpg" alt="step1" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        2. В открывшемся окне нажмите <b>«Добавить ярлык на телефон»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_yandex2.jpg" alt="step2" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        3. В открывшемся окне нажмите <b>«На экран «Домой»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_yandex3.jpg" alt="step3" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        4. В открывшемся окне нажмите <b>«Добавить»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_yandex4.jpg" alt="step4" /></div>
    </div>
  </div>
)


const IOSFirefoxIntructions = () => (
  <div className="instruction">
    <div className="instructionStep">
      <div className="instructionStep__text">
        1. Нажмите на иконку <SvgIcon icon="menu" />
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_firefox1.jpg" alt="step1" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        2. В открывшемся окне нажмите <b>«Поделиться»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_firefox2.jpg" alt="step2" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        3. В открывшемся окне нажмите <b>«На экран «Домой»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_firefox3.jpg" alt="step3" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        4. В открывшемся окне нажмите <b>«Добавить»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/ios_firefox4.jpg" alt="step4" /></div>
    </div>
  </div>
)


const AndroidFirefoxIntructions = () => (
  <div className="instruction">
    <div className="instructionStep">
      <div className="instructionStep__text">
        1. Нажмите на иконку <SvgIcon icon="dots" />
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_firefox1.jpg" alt="step1" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        2. В открывшемся окне нажмите <b>«Установить»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_firefox2.jpg" alt="step2" /></div>
    </div>
  </div>
)


const AndroidYandexIntructions = () => (
  <div className="instruction">
    <div className="instructionStep">
      <div className="instructionStep__text">
        1. Нажмите на иконку <SvgIcon icon="dots" />
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_yandex1.jpg" alt="step1" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        2. В открывшемся окне нажмите <b>«Добавить ярлык на рабочий стол»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_yandex2.jpg" alt="step2" /></div>
    </div>
  </div>
)


const AndroidOperaIntructions = () => (
  <div className="instruction">
    <div className="instructionStep">
      <div className="instructionStep__text">
        1. Нажмите на иконку <SvgIcon icon="dots" />
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_opera1.jpg" alt="step1" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        2. В открывшемся окне нажмите <b>«Добавить в...»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_opera2.jpg" alt="step2" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        3. В открывшемся окне нажмите <b>«Главный экран»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_opera3.jpg" alt="step3" /></div>
    </div>

    <div className="instructionStep">
      <div className="instructionStep__text">
        4. В открывшемся окне нажмите <b>«Добавить»</b>
      </div>
      <div className="instructionStep__pic"><img src="/img/addtohome/android_opera4.jpg" alt="step4" /></div>
    </div>
  </div>
)


const getIstructions = (ios, android, safari, chrome, firefox, yandex, opera) => {
  if (ios) {
    if (chrome) {
      return <IOSChromeIntructions />
    } else if (firefox) {
      return <IOSFirefoxIntructions />
    } else if (yandex) {
      return <IOSYandexIntructions />
    }
  
    return <IOSSafariIntructions />
  }

  if (android) {
    if (firefox) {
      return <AndroidFirefoxIntructions />
    } else if (yandex) {
      return <AndroidYandexIntructions />
    } else if (opera) {
      return <AndroidOperaIntructions />
    }
  }

  return null;
}

const AddToHomePage = () => {
  const navigate = useNavigate();

  const os = (platform?.os?.family || '').toLowerCase();
  const android = os.includes('android');
  const ios = os.includes('ios');

  const platformName = (platform?.name || '').toLowerCase();
  const safari = platformName.includes('safari');
  const chrome = platformName.includes('chrome');
  const firefox = platformName.includes('firefox');
  const yandex = platformName.includes('yandex');
  const opera = platformName.includes('opera');

  const instructions = getIstructions(ios, android, safari, chrome, firefox, yandex, opera);
  
  useEffect(() => {
    if (!instructions) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line
  }, [instructions]);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="Добавить на домашний экран" showBack={true} />}
      withHeader={false}
      hideNav={true}
    >
      {instructions}
    </Page>
  );
};

export default AddToHomePage;
