import Countdown from 'react-countdown'
import moment from 'moment'

import PassCard from 'components/PassCard'
import { useParkingWS } from 'models/parkingPass/useHooks'


const Info = ({ freePass }) => {
  if (freePass?.status === 'new') {
    return <span>Активир. до {moment(freePass?.expirationDate).format('DD.MM HH:mm')}</span>
  }
  if (freePass?.status === 'active') {
    return (
      <>
        <div><span>Бесплатно до {moment(freePass?.freeDate).format('DD.MM HH:mm')}</span></div>
        <div>
          <span>Осталось: </span>
          <Countdown
            date={freePass?.freeDate}
            daysInHours={true}
            renderer={({ hours, minutes, seconds, completed, formatted }) => {
              if (completed) {
                return '-';
              } else {
                const h = hours ? `${hours}ч ` : '';
                const m = minutes ? `${minutes}м ` : '';
                const s = seconds ? `${formatted.seconds}с ` : '';
                return <span>{h}{m}{s}</span>;
              }
            }}
          />
        </div>
      </>
    )
  }

  return '1 час бесплатно';
}


const ParkingPassCard = ({ style, hide = false }) => {
  const { freePass } = useParkingWS();

  const isLoading = freePass === null;
  const hasActive = freePass?.status === 'new' || freePass?.status === 'active';
  const url = hasActive ? '/parking/free/' : '/parking/';

  if (hide) return null;

  return (
    <PassCard
      style={style}
      title="Паркинг-ключ"
      url={url}
      info={<Info freePass={freePass} />}
      icon="/img/icons/pass-parking.png"
      isLoading={isLoading}
    />
  );
};

export default ParkingPassCard;
