import React, { useEffect, useCallback } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from "react-hook-form"

import AuthApi from 'models/auth/api'
import { setAuthNext } from 'models/auth/authSlice'
import { Form, Fieldset, TextField, PasswordField } from "components/forms"
import Button from "components/Button"
import SvgIcon from 'components/SvgIcon'

import { YANDEX_CLIENT_ID, MAIL_CLIENT_ID, VK_APP_ID, APP_URL } from 'config'

import './styles.css'


const PassLoginForm = () => {
  const { control, handleSubmit, reset, formState, setError, clearErrors } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const [loginUser, updateProps] = AuthApi.useLoginUserMutation();

  const onSubmit = data => {
    if (!updateProps.isLoading) {
      console.log(data);
      loginUser(data);
    }
  }

  const onError = (errors, e) => {
    console.log(errors, e);
  }

  useEffect(() => {
    if (token) {
      if (!user?.first_name || !user?.last_name) {
        navigate('/profile/edit/', { state: { from: location.state?.from || '/', replace: true } });
      } else {
        navigate(location.state?.from || '/', { replace: true });
      }
    }
  }, [token, user?.first_name, user?.last_name, location.state?.from, navigate]);

  /*const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = `${APP_URL}/login/google/`;

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');

    const params = {
      response_type: 'code',
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: redirectUri,
      prompt: 'select_account',
      access_type: 'offline',
      scope
    };

    const urlParams = new URLSearchParams(params).toString();

    dispatch(setAuthNext(location.state?.from));

    window.location = `${googleAuthUrl}?${urlParams}`;

    // eslint-disable-next-line
  }, []);
  */

  const openYandexLoginPage = useCallback(() => {
    const authUrl = 'https://oauth.yandex.ru/authorize';
    const redirectUri = `${APP_URL}/login/yandex/`;

    const params = {
      response_type: 'code',
      client_id: YANDEX_CLIENT_ID,
      redirect_uri: redirectUri,
    };

    const urlParams = new URLSearchParams(params).toString();

    dispatch(setAuthNext(location.state?.from));

    window.location = `${authUrl}?${urlParams}`;
    // eslint-disable-next-line
  }, []);

  const openMailLoginPage = useCallback(() => {
    const authUrl = 'https://oauth.mail.ru/login';
    const redirectUri = `${APP_URL}/login/mail/`;

    const params = {
      response_type: 'code',
      client_id: MAIL_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: 'userinfo',
      state: 'test',
    };

    const urlParams = new URLSearchParams(params).toString();

    dispatch(setAuthNext(location.state?.from));

    window.location = `${authUrl}?${urlParams}`;
    // eslint-disable-next-line
  }, []);

  const openVKLoginPage = useCallback(() => {
    const authUrl = 'https://id.vk.com/auth';
    const redirectUri = `${APP_URL}/login/vk/`;

    const params = {
      response_type: 'silent_token',
      app_id: VK_APP_ID,
      redirect_uri: redirectUri,
      uuid: 'territory3000',
    };

    const urlParams = new URLSearchParams(params).toString();

    dispatch(setAuthNext(location.state?.from));

    window.location = `${authUrl}?${urlParams}`;
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      className="loginForm"
      control={control}
      formState={formState}
      onSubmit={onSubmit}
      onError={onError}
      handleSubmit={handleSubmit}
      reset={reset}
      setError={setError}
      clearErrors={clearErrors}
      status={{
        isLoading: updateProps.isLoading,
        isSuccess: updateProps.isSuccess,
        isError: updateProps.isError,
        errorData: updateProps.error?.data,
        successData: updateProps.data,
      }}
      formFooter={(status) => (
        <>
          <Button type="submit" title="Войти" size="md" loading={status.isLoading} />

          <div className="altLogin">
            <div className="altLoginOr">или</div>

            <Link className="altLoginLink yandex" to="/login/" onClick={openYandexLoginPage} title="Войти с yandex" size="md">
              <SvgIcon icon="yandex" style={{ fontSize: '24px' }} /> Войти через Яндекс
            </Link>

            <Link className="altLoginLink mail" to="/login/" onClick={openMailLoginPage} title="Войти с mail.ru" size="md">
              <SvgIcon icon="mail" style={{ fontSize: '24px' }} /> Войти через Mail.ru
            </Link>

            <Link className="altLoginLink vk" to="/login/" onClick={openVKLoginPage} title="Войти с VK" size="md">
              <SvgIcon icon="vk" style={{ fontSize: '24px' }} /> Войти через VK
            </Link>
          </div>
        </>

      )}
      formPrivacy1={() => (
        <>Нажимая кнопку «Войти», вы соглашаетесь c&nbsp;<Link to="/privacy/">условиями политики</Link> конфиденциальности</>
      )}
    >
      <div className="formHeader">
        <div className="formHeaderTitle">Вход с паролем</div>
      </div>

      <Fieldset>
        <TextField
          name="phoneOrEmail"
          //label="Email"
          placeholder="Введите номер телефона или email"
          required={true}
        />

        <PasswordField
          name="password"
          //label="Пароль"
          placeholder="Введите пароль"
          required={true}
        />
      </Fieldset>
    </Form>
  );
};

export default PassLoginForm;
