import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/hall/api'
import HallListItem from '../HallListItem'

import "./styles.css"


const HallList = () => {
  const halls = useSelector(state => state.hall.list);

  const [getObjectList] = useLazyGetListQuery();
  useEffect(() => {
    getObjectList();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="hallList">
        {halls.map(hall => <HallListItem key={`hall${hall.slug}`} hall={hall} />)}
      </div>
    </div>
  );
};

export default HallList;
