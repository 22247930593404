import React, { useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

import { useLazyGetDetailQuery } from 'models/news/api'
import { Page, TopBar } from 'layout'
import DetailHeader from 'components/DetailHeader'
import DetailText from 'components/DetailText'

import './styles.css'


const NewsDetailPage = () => {
  let { postSlug } = useParams();
  const postDetail = useSelector(state => state.news.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  useEffect(() => {
    getObjectDetail(postSlug);
    // eslint-disable-next-line
  }, [postSlug]);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={postDetail?.title} showBack={true} />}
      hideNav={true}
      isLoading={getObjectDetailProps.isLoading}
    >
      <DetailHeader
        image={postDetail?.images}
      />

      <DetailText text={postDetail?.text}/>
    </Page>
  );
};

export default NewsDetailPage;
