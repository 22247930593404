import "./styles.css"


const TenantListItem = ({ tenant, onOpenPlace }) => {
  const openPlace = () => {
    if (onOpenPlace) {
      const place = tenant.places[0];
      onOpenPlace(place, place.hall, place.tenant)
    }
  }

  const numbers = tenant.places.reduce((res, item) => {
    if (item.number) {
      res.push(item.number)
    }
    return res;
  }, []);

  const iconUrl = (tenant.icon && tenant.icon.length > 0) ? tenant.icon[0].url : null;

  return (
    <div className="tenantItem" onClick={openPlace}>
      {iconUrl && <img className="tenantItem__logo" src={iconUrl} alt="" />}
      <div className="tenantItem__body">
        <div className="tenantItem__title">{tenant.name}</div>
        {numbers.length > 0 && <div className="tenantItem__place">Кабинет: {numbers.join(', ')}</div>}
      </div>
    </div>
  );
};

export default TenantListItem;
