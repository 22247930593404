import { api } from 'redux/api/api'


const CardRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardRequestList: builder.query({
      query: (params = null) => ({
        url: 'card/request/',
        params: params,
      }),
    }),
    getCardRequestDetail: builder.query({
      query: (id) => ({
        url: `card/request/${id}/`,
      }),
    }),
    getCardRequestForEdit: builder.query({
      query: (id) => ({
        url: `card/request/${id}/?forEdit=1`,
      }),
    }),

    updateCardRequest: builder.mutation({
      query(formData) {
        return {
          url: 'card/request/',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
    }),
  }),
});

export default CardRequestApi;

const {
  useLazyGetCardRequestListQuery,
  useLazyGetCardRequestDetailQuery,
  useLazyGetCardRequestForEditQuery,
  useUpdateCardRequestMutation,
} = CardRequestApi;

export {
  useLazyGetCardRequestListQuery as useLazyGetListQuery,
  useLazyGetCardRequestDetailQuery as useLazyGetDetailQuery,
  useLazyGetCardRequestForEditQuery as useLazyGetForEditQuery,
  useUpdateCardRequestMutation as useUpdateMutation,
};


