import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import AuthApi from 'models/auth/api'
import HallRequestApi from 'models/hallRequest/api'
import HallAccessApi from 'models/hallAccess/api'


const initialState = {
  dataIsLoaded: false,

  showDrawer: false,
  showNavigation: true,
  isModal: false,
  isFixed: false,
}

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    initCompleted: (state) => {
      state.dataIsLoaded = true;
    },

    toggleDrawer: (state, action) => {
      state.showDrawer = action.payload;
    },

    toggleNavigation: (state, action) => {
      state.showNavigation = action.payload;
    },

    toggleModal: (state, action) => {
      state.isModal = action.payload;
    },

    toggleFixed: (state, action) => {
      state.isFixed = action.payload;
    },
  },
});

export default appSlice.reducer;


const appInit = createAsyncThunk(
  'app/init',
  async (arg, thunkAPI) => {
    const tokenOld = thunkAPI.getState().auth.token;
    if (tokenOld) {
      await thunkAPI.dispatch(AuthApi.endpoints.tokenRefresh.initiate(tokenOld));
      console.log('appInit: load initials data');

      await thunkAPI.dispatch(HallRequestApi.endpoints.getHallRequestList.initiate({ actual: true }));
      await thunkAPI.dispatch(HallAccessApi.endpoints.getHallAccessList.initiate({ actual: true }));

      await thunkAPI.dispatch(appSlice.initCompleted());
      return true;
    }
    return false;
  }
)

const appActions = appSlice.actions;

export { appInit, appActions }
