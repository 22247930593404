import { NavLink, useLocation } from "react-router-dom"
import { useSelector } from 'react-redux'

import "./styles.css"


function Navigation() {
  const location = useLocation();
  const hallRequestActualCount = useSelector(state => state.hallRequest.actualCount);
  const hallAccessActualCount = useSelector(state => state.hallAccess.actualCount);

  const navItems = [
    {
      path: '/',
      title: 'Главная',
      icon: '/img/icons/home.png'
    },
    {
      path: '/events/',
      title: 'События',
      icon: '/img/icons/event.png'
    },
    {
      path: '/club/',
      title: 'Клубная карта',
      icon: '/img/icons/card_fill.png',
      main: false,
    },
    {
      path: '/hall/',
      title: 'Залы',
      icon: '/img/icons/conference-room.png'
    },
    /*{
      path: '/parking/',
      title: 'Парковка',
      icon: '/img/icons/park.png'
    },*/
    {
      path: '/profile/',
      title: 'Профиль',
      icon: '/img/icons/user.png'
    },
  ];

  const getCount = (item) => {
    switch (item.path) {
      case '/hall/':
        return hallRequestActualCount + hallAccessActualCount;
      default:
        return 0;
    }
  }

  return (
    <div className="nav">
      {navItems.map((item, i) => {
        let cls = "nav__item";
        if (item.main) {
          cls += " main";
        }
        if (location.pathname === item.path || (location.pathname.startsWith(item.path) && item.path !== '/')) {
          cls += " active";
        }
        const count = getCount(item);

        return (
          <NavLink
            key={`nav${i}`}
            to={item.path}
            className={cls}
            end={false}
          >
            <div>
              <img className="nav__itemIcon" src={item.icon} alt={item.title} />
              <span className="nav__itemTitle">{item.title}</span>
              {count > 0 && <span className="nav__itemCount">{count}</span>}
            </div>
          </NavLink>
        )
      })}
    </div>
  );
}

export default Navigation;
