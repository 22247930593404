import { useSelector } from 'react-redux'

import { Page, Header, Panel } from 'layout'
import HallList from 'models/hall/components/HallList'
import HallRequestList from 'models/hallRequest/components/HallRequestList'
import HallAccessList from 'models/hallAccess/components/HallAccessList'

import './styles.css'


const HallPage = () => {
  const hallRequestActualCount = useSelector(state => state.hallRequest.actualCount);
  const hallAccessActualCount = useSelector(state => state.hallAccess.actualCount);

  return (
    <Page
      renderHeader={(pageRef) => <Header pageRef={pageRef} title="Залы" />}
      withHeader={true}
    >
      {hallAccessActualCount > 0 &&
        <Panel title="Активные доступы" style={{ padding: "36px 0 0" }} padding="0">
          <HallAccessList filter={{ actual: true }} />
        </Panel>}

      {hallRequestActualCount > 0 &&
        <Panel title="Активные заявки" style={{ padding: "36px 0 0" }} padding="0">
          <HallRequestList filter={{ actual: true }} />
        </Panel>}

      <Panel title="Действующие залы">
        <HallList />
      </Panel>
    </Page>
  );
};

export default HallPage;
