import React from 'react'

import { Page, TopBar } from 'layout'
import FeedbackRequestForm from 'models/feedback/components/FeedbackRequestForm'

import './styles.css'


const FeedbackRequestPage = () => {
  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="Заявка на обслуживание" showBack={true} />}
      hideNav={true}
    >
      <FeedbackRequestForm />
    </Page>
  );
};

export default FeedbackRequestPage;
