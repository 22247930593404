import "./styles.css"


const ReadonlyField = ({ value, label, color }) => {
  return (
    <div className={`readonlyField ${color}`}>
      {label && <label>{label}</label>}
      <div className="value">{value}</div>
    </div>
  );
};

export default ReadonlyField;
