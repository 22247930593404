import React from 'react'

import { Page, TopBar, Panel } from 'layout'
import ContactItem from 'components/ContactItem'
//import FeedbackRequestPanel from 'models/feedback/components/FeedbackRequestPanel'

import './styles.css'


const FeedbackPage = () => {
  return (
    <Page
      renderHeader={(pageRef) => <TopBar title="Связь с администрацией" showBack={true} />}
      hideNav={true}
    >
      <Panel title="Контакты администрации" titleSize="lg">
        <ContactItem icon="phone" title="Аренда помещений" value="+7 (347) 294–00–97" url="tel:+73472940097" />
        <ContactItem icon="phone" title="Аренда мест и офисов в коворкинге" value="+7 917 348-03-31" url="tel:+79173480331" />
        <ContactItem icon="phone" title="Аренда конференц-залов" value="+7 917 797-97-99" url="tel:+79177979799" />
        <ContactItem icon="phone" title="Оплата парковочных абонементов" value="+7 917 760-07-72" url="tel:+79177600772" />
      </Panel>
    </Page>
  );
};

export default FeedbackPage;
