import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  requests: [
    {
      id: 4,
      question: "Перегорела лампочка",
      lastMessage: null,
      date: "25.08.2022 10:20",
      status: 'open',
    },
    {
      id: 3,
      question: "Не работает что-то в зоне общего пользования на 4 этаже здания",
      lastMessage: null,
      date: "23.08.2022 09:46",
      status: 'inProgress',
    },
    {
      id: 2,
      question: "Не работает что-то в зоне общего пользования на 4 этаже здания",
      lastMessage: {
        author: "Администрация",
        date: "21.08.2022 10:00",
        text: "Проблема решена",
      },
      date: "20.08.2022 17:23",
      status: 'completed',
    },
    {
      id: 1,
      question: "Не работает что-то в зоне общего пользования на 4 этаже здания",
      lastMessage: {
        author: "Администрация",
        date: "13.08.2022 09:35",
        text: "Починили",
      },
      date: "12.08.2022 16:09",
      status: 'completed',
    },
  ],
  details: {
    4: {
      id: 4,
      author: "Владлен Кантимеров",
      question: "Перегорела лампочка",
      messages: [],
      date: "25.08.2022 10:20",
      status: 'open',
    },
    3: {
      id: 3,
      author: "Владлен Кантимеров",
      question: "Не работает что-то в зоне общего пользования на 4 этаже здания",
      messages: [],
      date: "23.08.2022 09:46",
      status: 'inProgress',
    },
    2: {
      id: 2,
      author: "Владлен Кантимеров",
      question: "Не работает что-то в зоне общего пользования на 4 этаже здания",
      messages: [{
        id: 1,
        date: "21.08.2022 10:00",
        text: "Проблема решена",
      }],
      date: "20.08.2022 17:23",
      status: 'completed',
    },
    1: {
      id: 1,
      author: "Владлен Кантимеров",
      question: "Не работает что-то в зоне общего пользования",
      messages: [{
        id: 1,
        author: "Администрация",
        date: "12.08.2022 17:03",
        text: "Уточните, пожалуйста, на каком этаже поломка?",
      }, {
        id: 2,
        isMy: true,
        author: "Владлен Кантимеров",
        date: "12.08.2022 17:35",
        text: "3 этаж",
      }, {
        id: 3,
        author: "Администрация",
        date: "13.08.2022 09:35",
        text: "Починили",
      }],
      date: "12.08.2022 16:09",
      status: 'completed',
    },
  },
};


const feedbackSlice = createSlice({
  name: 'feedbackSlice',
  initialState,
  reducers: {},
});

export default feedbackSlice.reducer;
