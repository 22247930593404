import { useEffect, useMemo } from 'react'

import Loader from 'components/Loader'


const ListLoadComponent = ({ getObjectList, getObjectListProps, filter, render, onLoaded }) => {
  const items = useMemo(() => {
    if (getObjectListProps.isSuccess) {
      return getObjectListProps.data.results;
    }
    return []
  }, [getObjectListProps.isSuccess, getObjectListProps.data])

  useEffect(() => {
    const params = Object.assign(filter || {}, { limit: 5000 });
    getObjectList(params);
  }, [filter, getObjectList]);

  useEffect(() => {
    if (getObjectListProps.isSuccess) {
      onLoaded && onLoaded(getObjectListProps.data.results);
    }
  }, [onLoaded, getObjectListProps.isSuccess, getObjectListProps.data]);

  return <Loader isLoading={getObjectListProps.isLoading}>{render({ items })}</Loader>;
};

export default ListLoadComponent;
