import { api } from 'redux/api/api'


const ParkingPassApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getParkingPassList: builder.query({
      query: (params = null) => ({
        url: 'parking/pass/',
        params: params,
      }),
    }),
    getParkingPassDetail: builder.query({
      query: (id) => ({
        url: `parking/pass/${id}/`,
      }),
    }),

    getParkingPassFree: builder.query({
      query: () => ({
        url: `parking/pass/free/`,
      }),
    }),

    createParkingPassFree: builder.mutation({
      query() {
        return {
          url: 'parking/pass/free/',
          method: 'POST',
        };
      },
    }),
  }),
});

export default ParkingPassApi;

const {
  useLazyGetParkingPassListQuery,
  useLazyGetParkingPassDetailQuery,
  useLazyGetParkingPassFreeQuery,
  useCreateParkingPassFreeMutation,

} = ParkingPassApi;

export {
  useLazyGetParkingPassListQuery as useLazyGetListQuery,
  useLazyGetParkingPassDetailQuery as useLazyGetDetailQuery,
  useLazyGetParkingPassFreeQuery,
  useCreateParkingPassFreeMutation
};

