import React from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

import { Page, TopBar } from 'layout'
import FeedbackMessage from 'models/feedback/components/FeedbackMessage'

import './styles.css'


const FeedbackRequestDetailPage = () => {
  let { requestId } = useParams();
  const requestDetail = useSelector(state => state.feedback.details[requestId]);

  return (
    <Page
      renderHeader={(pageRef) => <TopBar title={`Заявка №${requestDetail.id}`} showBack={true} />}
      hideNav={true}
    >
      <FeedbackMessage date={requestDetail.date} author={requestDetail.author} text={requestDetail.question} isMy={true} />

      {requestDetail.messages.map(msg => (
        <FeedbackMessage key={`msg${msg.id}`} date={msg.date} author={msg.author} text={msg.text} isMy={msg.isMy} />
      ))}
    </Page>
  );
};

export default FeedbackRequestDetailPage;
