import { api } from 'redux/api/api'


const HallRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHallRequestList: builder.query({
      query: (params = null) => ({
        url: 'hall/request/',
        params: params,
      }),
    }),
    getHallRequestDetail: builder.query({
      query: (id) => ({
        url: `hall/request/${id}/`,
      }),
    }),
    getHallRequestForEdit: builder.query({
      query: (id) => ({
        url: `hall/request/${id}/?forEdit=1`,
      }),
    }),

    updateHallRequest: builder.mutation({
      query(values) {
        const url = values.id ? `hall/request/${values.id}/`: 'hall/request/';
        const method = values.id ? `PUT`: 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
  }),
});

export default HallRequestApi;

const {
  useLazyGetHallRequestListQuery,
  useLazyGetHallRequestDetailQuery,
  useLazyGetHallRequestForEditQuery,
  useUpdateHallRequestMutation,
} = HallRequestApi;

export {
  useLazyGetHallRequestListQuery as useLazyGetListQuery,
  useLazyGetHallRequestDetailQuery as useLazyGetDetailQuery,
  useLazyGetHallRequestForEditQuery as useLazyGetForEditQuery,
  useUpdateHallRequestMutation as useUpdateMutation,
};


