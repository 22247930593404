import "./styles.css"


const Panel = ({ children, title, titleSize, padding, shadowBottom, style }) => {
  let cls = "panel";
  if (shadowBottom) cls += " panel_with_shadow";

  let clsTitle = "panelTitle";
  if (titleSize) clsTitle += " panelTitle_size_" + titleSize;

  let bodyStyle = {};
  if (padding) bodyStyle['padding'] = padding;

  return (
    <div className={cls} style={style}>
      {title && <div className={clsTitle}>{title}</div>}
      <div className="panelBody" style={bodyStyle}>{children}</div>
    </div>
  );
};

export default Panel;
